import React, {useState, useContext, useEffect} from 'react';
import ShoppingContext from '../../../context/ShoppingContext';

const imagesPath = process.env.REACT_APP_BACKEND_URL+"/api/media/products/";

const ProductDetailsModal = ({ product, onClose, i18n, t }) => {
    const [isAgeRestricted, setIsAgeRestricted] = useState(false);

    const { addCartItems, cart, isLoading } = useContext(ShoppingContext);
    // const handleCloseError = () => {
    //     dispatch({ type: 'ERROR', payload: null }); // Reset the error state
    // };
    console.log("SEARCHING FOR ITEM");

    useEffect(() => {
        if (product.age_restriction) {
            setIsAgeRestricted(true);
            // Optionally, you can set a timeout to automatically close the popup after a few seconds
        }
    }, [product]);


    const getCurrentItemQuantity = () => {
        // Ensure cart.orderItems is defined and is an array before proceeding
        if (Array.isArray(cart.orderItems)) {
            console.log("SEARCHING FOR ITEM");
            // Find the cart item that matches the current product by its ID (or any unique identifier)
            const currentItem = cart.orderItems.find(cartItem => cartItem.productId.toString().trim() === product._id.toString().trim());
            // If the item is found, return its quantity (converted to a number to ensure it's numeric)
            if (currentItem) {
                // console.log("currentItem");
                // console.log(currentItem);
                return Number(currentItem.quantity) || 0;
            }
        }
        // Return 0 if cart.orderItems is not an array, is undefined, or the item is not found
        return 0;
    };

    const [quantity, setQuantity] = useState(getCurrentItemQuantity() || 0);
    const [maxQuantityReached, setMaxQuantityReached] = useState(false);
    const [maxQuantityReachedMessage, setMaxQuantityReachedMessage] = useState('');
    const [lastClickTime, setLastClickTime] = useState(0);
    const clickDelay = 100; // 200 milliseconds

    // console.log(getCurrentItemQuantity());




    const maxTotalCartQuantity = 6; // Maximum total quantity allowed in the cart
    const maxProductQuantity = product.stock; // Assuming each product has a 'stock' property

    // Function to calculate the total quantity in the cart
    const getTotalCartQuantity = () => {
        // Ensure cart.orderItems is defined and is an array before proceeding
        if (Array.isArray(cart.orderItems)) {
            // Use reduce to sum up the quantity of each cart item
            return cart.orderItems.reduce((totalQuantity, cartItem) => {
                // Ensure cartItem.quantity is a number to avoid NaN errors
                const itemQuantity = Number(cartItem.quantity) || 0;
                return totalQuantity + itemQuantity;
            }, 0);
        }
        // Return 0 if cart.orderItems is not an array or is undefined
        return 0;
    };

    const updateQuantity = async (newQuantity) => {
       console.log('test');
        try {
            // Assuming addCartItems is an async function that returns a Promise
            await addCartItems({ ...product, quantity: newQuantity, itemPrice: product.price });
            // Here you can add any logic that should happen after successfully adding the item
        } catch (error) {
            // Handle any errors that occur during the add to cart process
            console.error("Failed to update quantity in cart", error);
            // Optionally, provide user feedback about the error
        }
    };

    const changeQuantity = (change) => {
        console.log('changeQuantity');

        const currentTime = new Date().getTime();
        if (currentTime - lastClickTime > clickDelay) {
            let newQuantity = quantity;
            let totalCartQuantity = getTotalCartQuantity();

            console.log('total');
            console.log(totalCartQuantity);
            if (change === 'increase') {
                // let lastQuantity = quantity;
                newQuantity = quantity + 1;
                if (totalCartQuantity + 1 > maxTotalCartQuantity) {
                    setMaxQuantityReached(true);
                    setMaxQuantityReachedMessage('Total cart quantity cannot exceed 6.');
                    return;
                } else if (newQuantity > maxProductQuantity) {
                    setMaxQuantityReached(true);
                    setMaxQuantityReachedMessage('Maximum stock for this product reached.');
                    return;
                } else {
                    setQuantity(newQuantity);
                    updateQuantity(newQuantity);
                    return;
                }
            } else if (change === 'decrease' && quantity >= 0) {

                if (quantity > 0) {
                    newQuantity = quantity - 1;
                    setQuantity(newQuantity);
                    updateQuantity(newQuantity);
                    return;
                } else {
                    // If decrease would set quantity below 0, do nothing
                }
            }
            setMaxQuantityReached(false);
            setMaxQuantityReachedMessage('');

            setLastClickTime(currentTime);
        }
    };

    // const handleAddToCart = async () => {
    //     try {
    //         // Assuming addCartItems is an async function
    //         await addCartItems({ ...product, quantity: quantity, itemPrice: product.price });
    //         // Optionally update quantity or perform other actions after adding to cart
    //         // updateQuantity(quantity);
    //         onClose(); // Close the modal after adding to cart
    //     } catch (error) {
    //         console.error("Failed to add item to cart:", error);
    //     }
    // };

    // This function will be called when the backdrop is clicked
    const handleBackdropClick = () => {
        onClose(); // Close the modal
    };

    // This function prevents click events from propagating to the backdrop
    const handleModalContentClick = (event) => {
        event.stopPropagation(); // Prevent click from closing the modal when clicking inside
    };

    // console.log(product)
    return (
        <div className="item-details-modal-backdrop" onClick={handleBackdropClick}>
            {isLoading &&
            <Loader/>} {/* Show loader when adding to cart */}
            {/*{error && <ErrorPopup*/}
            {/*    message={error}*/}
            {/*    onClose={handleCloseError}*/}
            {/*    t={t}*/}
            {/*/>*/}
            {/*}*/}

            <div className="item-details-modal-content" onClick={handleModalContentClick}>

                {isAgeRestricted && (
                    <div className="age-restriction-popup">
                        <div className="age-restriction-popup-block">
                            {/* Age restriction message and close button */}
                            <div className="age-restriction-popup-message-text">{t("We need age verification.")}</div>
                            <div className="age-restriction-popup-message-text-content">{t("Are you over 18?")}</div>
                            <div className="age-restriction-popup-message-text-content">{t("The purchase is made at your own risk.")}</div>
                            <button className="age-restriction-popup-message-button restriction-button-noconfirm" onClick={onClose}>{t('NO')}</button>
                            <button className="age-restriction-popup-message-button restriction-button-confirm" onClick={() => setIsAgeRestricted(false)}>{t('YES')}</button>
                        </div>
                    </div>
                )}


                <div className="item-details-top-content">
                    <div className="item-details-top-content-left-side">
                    {product.images && product.images.length > 0 && (
                        <img
                            src={`${imagesPath}${product.images[0].path}`}
                            alt={product.barcode}
                        />
                    )}
                    </div>
                    <div className="item-details-top-content-right-side">
                        <div className="item-details-name">
                            {product.name?.[i18n.language] ?? product.name?.el}
                        </div>
                        <div className="item-details-price">
                            {product.price}€
                        </div>
                    </div>
                </div>
                <div className="item-details-middle-content">
                    {product.description?.[i18n.language] || product.description?.el ? (
                        <div className="item-details-description item-details-block">
                            <div className="item-details-description-title item-details-block-title">
                                {t('Description')}
                            </div>
                            <div className="item-details-description-data item-details-block-data">
                                {product.description?.[i18n.language] ?? product.description?.el}
                            </div>
                        </div>
                    ) : null}

                    {product.allergens?.[i18n.language] || product.allergens?.el ? (
                        <div className="item-details-allergens item-details-block">
                            <div className="item-details-allergens-title item-details-block-title">
                                {t('Allergens')}
                            </div>
                            <div className="item-details-allergens-data item-details-block-data">
                                {product.allergens?.[i18n.language] ?? product.allergens?.el}
                            </div>
                        </div>
                    ) : null}

                    <div className="item-details-ingredients item-details-block">
                        {product.ingredients?.[i18n.language] || product.ingredients?.el ? (
                            <>
                                <div className="item-details-ingredients-title item-details-block-title">
                                    {t('Ingredients')}
                                </div>
                                <div className="item-details-ingredients-data item-details-block-data">
                                    {product.ingredients?.[i18n.language] ?? product.ingredients?.el}
                                </div>
                            </>
                        ) : null}
                    </div>
                    <div className="item-details-quantity-selector">
                        {maxQuantityReached && <div className="max-quantity-message-popup">
                            <div className="max-quantity-message-block">
                                <div className="max-quantity-message-icon">
                                    <img
                                        width='100'
                                        height='100'
                                        src='/img/tick-icon.png'
                                        className='img-fluid payment-icon'
                                        alt='logo'
                                    />
                                </div>
                                <div className="max-quantity-message-text">{t('Maximum quantity reached')}</div>
                                <div className="max-quantity-message-text-content">{t(maxQuantityReachedMessage)}</div>
                                <button className="max-quantity-message-button"
                                        onClick={() => setMaxQuantityReached(false)}>{t('OK')}</button>
                            </div>
                        </div>}
                        <div className="cart-item-quantity-selector-title">{t('Quantity')}</div>
                        <div className="item-details-quantity-selector-container">
                            <button className="quantity-less-button qty-select-button"
                                    onClick={() => changeQuantity('decrease')}>-
                            </button>
                            <input className="cart-item-quantity-selector-input" data-quantity={quantity} type="text" value={quantity} readOnly/>
                            <button className="quantity-more-button qty-select-button"
                                    onClick={() => changeQuantity('increase')}>+
                            </button>
                        </div>
                    </div>
                </div>
                <div className="item-details-bottom-content">
                    <div className="item-details-close-button">
                        <div className="item-details-close-inner">
                            <button onClick={onClose}>{t("Close")}</button>
                        </div>
                    </div>
                    {/*<div className="item-details-add-to-cart-button">*/}
                    {/*    <div className="item-details-add-to-cart-button-inner">*/}
                    {/*    <button onClick={handleAddToCart}>{t("Add to cart")}</button>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
};


// Loader Component
const Loader = () => {
    return <div className="loader-block"><div className="loader-block-text">Loading...</div></div>; // You can style this or use a library component
};

// Error Popup Component
// const ErrorPopup = ({ message, onClose , t}) => {
//     return (
//         <div className="error-popup">
//             <p>{t(message)}</p>
//             <button onClick={onClose}>{t("Close")}</button> {/* Close button */}
//         </div>
//     );
// };


export default ProductDetailsModal;