import {Spinner, Message} from '../../index'
import {
    dynamicInputSelect,
    // dynamicInputSelect,
    inputCheckBox,
    inputText,
    inputTextArea,
} from '../../../utils/dynamicForm';
import {
    inputImagesBlock
} from "../../../utils/imageTools";
import {
    languageOptions
} from "../../../config/languagesConfig";


export const FormProducts = ({
                                 edit,
                                 formCleanHandler,
                                 isLoading,
                                 register,
                                 isError,
                                 isErrorUpdate,
                                 errors,
                                 watch,
                                 isLoadingUpdate,
                                 isLoadingPost,
                                 handleSubmit,
                                 submitHandler,
                                 error,
                                 setValue,
                                 imageThumbnails,
                                 categoriesData,
                                 brandsData,
                                 images_path,
                                 setFileInputs,
                                 fileInputs,
                             }) => {

    // console.log(getDefaultCategoryOptions());
    // const categoryOptions = getDefaultCategoryOptions().map((option, index) => ({
    //   ...option,
    //   key: index.toString(),
    // }));


    return (

        <div
            className='modal fade'
            id='productModal'
            data-bs-backdrop='static'
            data-bs-keyboard='false'
            tabIndex='-1'
            aria-labelledby='productModalLabel'
            aria-hidden='true'
        >
            <div className='modal-dialog'>
                <div className='modal-content modal-background'>
                    <div className='modal-header'>
                        <h3 className='modal-title ' id='productModalyLabel'>
                            {edit ? 'Edit Products' : 'Add a NEW Product'}
                        </h3>
                        <button
                            type='button'
                            className='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={formCleanHandler}
                        ></button>
                    </div>
                    <div className='modal-body'>
                        {isLoading ? (
                            <Spinner/>
                        ) : isError ? (
                            <Message variant='danger'>{error}</Message>
                        ) : isErrorUpdate ? (
                            <Message variant='danger'>{error}</Message>
                        ) : (
                            <form onSubmit={handleSubmit(submitHandler)}>
                                <div className='basic-data form-group-block'>
                                    <div className='basic-data-title form-group-title'>ΠΛΗΡΟΦΟΡΙΕΣ</div>
                                    {inputCheckBox({
                                        register,
                                        errors,
                                        watch,
                                        label: 'Status',
                                        name: 'confirmed',
                                        placeholder: '',
                                        isRequired: false,
                                    })}
                                    {inputText({
                                        register,
                                        errors,
                                        label: 'SKU',
                                        name: 'sku',
                                        placeholder: '',
                                        isRequired: true,
                                    })}
                                    {inputText({
                                        register,
                                        errors,
                                        label: 'Barcode',
                                        name: 'barcode',
                                        placeholder: '',
                                        isRequired: false,
                                    })}
                                    {/*{inputText({*/}
                                    {/*    register,*/}
                                    {/*    errors,*/}
                                    {/*    label: 'Name',*/}
                                    {/*    name: 'name',*/}
                                    {/*    placeholder: '',*/}
                                    {/*    isRequired: true,*/}
                                    {/*})}*/}
                                    {languageOptions.map((lang) => inputText({
                                        register,
                                        errors,
                                        label: `Name`,
                                        name: `name_${lang.value}`,
                                        placeholder: "",
                                        lang: lang.value,
                                        isRequired: lang === 'el', // Make Greek mandatory
                                    }))}

                                    {languageOptions.map((lang) => inputTextArea({
                                        register,
                                        errors,
                                        label: `Description`,
                                        name: `description_${lang.value}`,
                                        placeholder: "",
                                        lang: lang.value,
                                        isRequired: lang === 'el', // Make Greek mandatory
                                    }))}

                                    {languageOptions.map((lang) => inputTextArea({
                                        register,
                                        errors,
                                        label: `Ingredients`,
                                        name: `ingredients_${lang.value}`,
                                        placeholder: "",
                                        lang: lang.value,
                                        isRequired: lang === 'el', // Make Greek mandatory
                                    }))}

                                    {languageOptions.map((lang) => inputTextArea({
                                        register,
                                        errors,
                                        label: `Allergens`,
                                        name: `allergens_${lang.value}`,
                                        placeholder: "",
                                        lang: lang.value,
                                        isRequired: lang === 'el', // Make Greek mandatory
                                    }))}

                                    {/*{inputTextArea({*/}
                                    {/*    register,*/}
                                    {/*    errors,*/}
                                    {/*    label: 'Description',*/}
                                    {/*    name: 'description',*/}
                                    {/*    placeholder: '',*/}
                                    {/*    isRequired: false,*/}
                                    {/*})}*/}
                                    {/*{inputTextArea({*/}
                                    {/*    register,*/}
                                    {/*    errors,*/}
                                    {/*    label: 'Ingredients',*/}
                                    {/*    name: 'ingredients',*/}
                                    {/*    placeholder: '',*/}
                                    {/*    isRequired: false,*/}
                                    {/*})}*/}
                                    {/*{inputTextArea({*/}
                                    {/*    register,*/}
                                    {/*    errors,*/}
                                    {/*    label: 'Allergens',*/}
                                    {/*    name: 'allergens',*/}
                                    {/*    placeholder: '',*/}
                                    {/*    isRequired: false,*/}
                                    {/*})}*/}
                                    {inputCheckBox({
                                        register,
                                        errors,
                                        watch,
                                        label: 'Age confirmation',
                                        name: 'age_restriction',
                                        placeholder: '',
                                        isRequired: false,
                                    })}
                                    {dynamicInputSelect({
                                        register,
                                        errors,
                                        label: 'Brand',
                                        name: 'brand',
                                        value: 'brand_name',
                                        data:
                                            brandsData &&
                                            brandsData.data,
                                        placeholder: 'Select Brand'
                                    })}
                                    {/*{inputText({*/}
                                    {/*    register,*/}
                                    {/*    errors,*/}
                                    {/*    label: 'Size',*/}
                                    {/*    name: 'size',*/}
                                    {/*    placeholder: '',*/}
                                    {/*    isRequired: false,*/}
                                    {/*})}*/}

                                    {/*{inputText({*/}
                                    {/*    register,*/}
                                    {/*    errors,*/}
                                    {/*    label: 'Tags',*/}
                                    {/*    name: 'tags',*/}
                                    {/*    placeholder: '',*/}
                                    {/*    isRequired: false,*/}
                                    {/*})}*/}
                                    {dynamicInputSelect({
                                        register,
                                        errors,
                                        label: 'Categories',
                                        name: 'categories',
                                        value: 'category_name',
                                        data:
                                            categoriesData &&
                                            categoriesData.data,
                                        placeholder: 'Select Category',
                                        translated: true
                                    })}

                                    {inputText({
                                        register,
                                        errors,
                                        label: 'Sort Index',
                                        name: 'sort_index',
                                        placeholder: '0',
                                        isRequired: false,
                                    })}

                                    {inputImagesBlock({
                                        setValue,
                                        errors,
                                        watch,
                                        register,
                                        label: 'Images',
                                        name: 'images',
                                        placeholder: '',
                                        isRequired: false,
                                        imageThumbnails,
                                        images_path,
                                        fileInputs,
                                        setFileInputs,
                                        maxImages: 5
                                    })}

                                    {/*{inputCheckBox({*/}
                                    {/*    register,*/}
                                    {/*    errors,*/}
                                    {/*    watch,*/}
                                    {/*    label: 'Blocked',*/}
                                    {/*    name: 'blocked',*/}
                                    {/*    placeholder: '',*/}
                                    {/*    isRequired: false,*/}
                                    {/*})}*/}

                                </div>

                                <div className='contact-data form-group-block'>
                                    <div className='contact-data-title form-group-title'>ΤΙΜΟΛΟΓΗΣΗ</div>
                                    {inputText({
                                        register,
                                        errors,
                                        label: 'Price',
                                        name: 'price',
                                        placeholder: '',
                                        isRequired: true,
                                    })}
                                    {/*{inputText({*/}
                                    {/*    register,*/}
                                    {/*    errors,*/}
                                    {/*    label: 'Price per kilo',*/}
                                    {/*    name: 'price_per_kilo',*/}
                                    {/*    placeholder: '',*/}
                                    {/*    isRequired: false,*/}
                                    {/*})}*/}
                                    {/*{inputText({*/}
                                    {/*    register,*/}
                                    {/*    errors,*/}
                                    {/*    label: 'Price with tax',*/}
                                    {/*    name: 'price_with_tax',*/}
                                    {/*    placeholder: '',*/}
                                    {/*    isRequired: false,*/}
                                    {/*})}*/}
                                    {inputText({
                                        register,
                                        errors,
                                        label: 'Tax%',
                                        name: 'tax',
                                        placeholder: '',
                                        isRequired: false,
                                    })}
                                </div>


                                <div className='modal-footer'>
                                    <button
                                        type='button'
                                        className='btn btn-secondary '
                                        data-bs-dismiss='modal'
                                        onClick={formCleanHandler}
                                    >
                                        Close
                                    </button>
                                    <button
                                        type='submit'
                                        className='btn btn-primary '
                                        disabled={isLoadingPost || isLoadingUpdate}
                                    >
                                        {isLoadingPost || isLoadingUpdate ? (
                                            <span className='spinner-border spinner-border-sm'/>
                                        ) : (
                                            "Save"
                                        )}
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormProducts
