import React, {useState} from 'react'
import {useContext} from 'react'
import Product from './Product'
import ShoppingContext from '../../../context/ShoppingContext'
import ProductDetailsModal from './ProductDetailsModal';
import { useTranslation } from "react-i18next";

// import AddProduct from './AddProduct'

function Products({category}) {
    const {i18n, t} = useTranslation(); // Use i18n instance to access current language

    const [selectedProduct, setSelectedProduct] = useState(null);

    const {products} = useContext(ShoppingContext)
    // Check if products is defined and is an array
    if (!Array.isArray(products)) {
        // You can return a loading spinner, placeholder, or null here
        return <div>Loading products...</div>;
    }

    const handleProductClick = (product) => {
        setSelectedProduct(product);
    };

    const handleCloseModal = () => {
        setSelectedProduct(null);
    };



    // Filter products based on the selected category
    const filteredProducts = category
        ? products.filter(product => product.categories.includes(category._id))
        : products;

    // console.log(filteredProducts)
    // console.log(filteredProducts)

    return (
        <div id="products-container" className="products-container">
            {filteredProducts.map((product) => (
                <Product
                    key={product._id}
                    product={product}
                    onProductClick={() => handleProductClick(product)}
                    i18n={i18n}
                />
            ))}
            {selectedProduct && (
                <ProductDetailsModal
                    product={selectedProduct}
                    onClose={handleCloseModal}
                    i18n={i18n}
                    t={t}
                />
            )}
        </div>
    );
}

export default Products