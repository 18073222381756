import React, {useContext} from 'react';
import ShoppingContext from "../../../context/ShoppingContext";
const imagesPath = process.env.REACT_APP_BACKEND_URL+"/api/media/categories/";

function Categories({onCategorySelect, i18n }) {

    const { categories } = useContext(ShoppingContext);


    // Fallback for undefined categories - render nothing or a placeholder
    if (!categories) {
        // You can return null, a loading spinner, or a placeholder message here
        return <div>Loading categories...</div>;
    }



    return (
        <div className="categories-container">

            {categories.map(category => (

                <div

                    key={category.category_id}
                    className="category-card"
                    onClick={() => onCategorySelect(category)}
                >
                    <div className="category-block-icon">
                        {category.images.length > 0 ? (
                            <img className="img-fluid category-icon" src={`${imagesPath}${category.images[0].path}`} alt="Product Thumbnail" />
                        ) : (
                            <img className="img-fluid category-icon" src={`${imagesPath}imall-item-no-image.png`} alt="Default Thumbnail" />
                        )}
                    </div>
                    <div className="category-block-text">
                        {category.category_name?.[i18n.language] ?? category.category_name?.el}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Categories;