import React, { useContext } from 'react'
import {FaTrash} from 'react-icons/fa'
import ShoppingContext from '../../../context/ShoppingContext'
const imagesPath = process.env.REACT_APP_BACKEND_URL + "/api/media/products/";


function CartItem({item,i18n}) {
    const {removeCartItems, products} = useContext(ShoppingContext)


    const product = products.find(p => p._id === item.productId);

    return (
    <div className="cart-item">
        <div className="cart-item-inner">
            <div className="cart-item-mini-inner-left">
                <div className="cart-item-mini-image">
                    {product.images && product.images.length > 0 && (
                        <img
                            src={`${imagesPath}${product.images[0].path}`}
                            alt={product.barcode}
                        />
                    )}
                </div>
            </div>
            <div className="cart-item-mini-inner-middle">
                <h3 className="cart-item-name">
                    {product.name?.[i18n.language] ?? product.name?.el}
                </h3>
                {/*<h3 className="cart-item-barcode">{item.barcode}</h3>*/}
            </div>
            <div className="cart-item-mini-inner-right">
                <div className="cart-item-delete-button" onClick={() => removeCartItems(item.productId)}>
                    <FaTrash/>
                </div>
                <div className="cart-item-quantity">
                    {item.quantity}x
                </div>
            </div>
        </div>
    </div>
    )
}

export default CartItem