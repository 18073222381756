import DeleteItemIcon from "../../../media/delete-item.svg";
import EditItemIcon from "../../../media/edit-item.svg";

const ViewRoles = ({
                       data,
                       editHandler,
                       deleteHandler
                   }) => {
    // Sort the data by role type
    // const sortedData = data?.data?.sort((a, b) => (a.type && b.type ? a.type.localeCompare(b.type) : 0));

    return (
        <div className="table-responsive bg-light p-3 mt-2">
            <table className="table table-sm table-border">
                <thead className="border-0">
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Description</th>
                    <th>Actions</th>
                </tr>
                </thead>

                <tbody>
                {data?.data?.map((role) => (
                    <tr key={role._id}>
                        <td>{role.name}</td>
                        <td>{role.type}</td>
                        <td>{role.description}</td>
                        <td>
                            <div className="btn-group">
                                <button
                                    className="btn"
                                    onClick={() => editHandler(role)}
                                    data-bs-toggle="modal"
                                    data-bs-target="#roleModal"
                                >
                                    <img src={EditItemIcon} alt="Edit"
                                         className="icon-img action-icons-img"/>
                                </button>

                                <button
                                    className="btn"
                                    onClick={() => deleteHandler(role._id)}
                                >
                                    <img src={DeleteItemIcon} alt="Delete"
                                         className="icon-img action-icons-img"/>
                                </button>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default ViewRoles;
