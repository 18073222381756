import dynamicAPI from './dynamicAPI';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useMessage } from '../context/AdminMessageContext';

const url = '/api/machinePriceRules';
const queryKey = 'machinePriceRules';

const useMachinePriceRulesHook = () => {
    const queryClient = useQueryClient();
    const { setSuccessMessage, setErrorMessage } = useMessage(); // Use the message context

    // Fetch all machine price rules with pagination and search query
    const getMachinePriceRules = ({ page = 1, q = '', limit = 25 }) =>
        useQuery([queryKey, page, limit], async () => {
            return await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}`, {});
        }, { retry: 0 });

    // Fetch a specific machine price rule by machine ID using `useQuery`
    const getMachinePriceRuleById = (machineId) =>
        useQuery([queryKey, machineId], async () => {
            return await dynamicAPI('get', `${url}/machine/${machineId}`, {});
        }, { retry: 0, enabled: !!machineId });  // Only enable when `machineId` is available

    // Update a specific machine price rule
    const updateMachinePriceRule = (machineId) =>
        useMutation(
            async ({ ruleId, updatedData }) =>
                await dynamicAPI('put', `${url}/machine/${machineId}/rule/${ruleId}`, updatedData),
            {
                retry: 0,
                onSuccess: () => {
                    setSuccessMessage('Machine Price Rule updated successfully.');
                    queryClient.invalidateQueries([queryKey, machineId]); // Invalidate the cache to refetch
                },
                onError: (error) => {
                    const errorMessage = error?.error || 'An error occurred while updating Machine Price Rule.';
                    setErrorMessage(errorMessage);
                },
            }
        );

    // Delete a specific machine price rule
    const deleteMachinePriceRule = (machineId) =>
        useMutation(
            async ({ ruleId }) =>
                await dynamicAPI('delete', `${url}/machine/${machineId}/rule/${ruleId}`, {}),
            {
                retry: 0,
                onSuccess: () => {
                    setSuccessMessage('Machine Price Rule deleted successfully.');
                    queryClient.invalidateQueries([queryKey, machineId]); // Invalidate the cache to refetch
                },
                onError: (error) => {
                    const errorMessage = error?.error || 'An error occurred while deleting Machine Price Rule.';
                    setErrorMessage(errorMessage);
                },
            }
        );

    // Create a new machine price rule
    const postMachinePriceRule = (machineId) =>
        useMutation(
            async (priceRule) =>
                await dynamicAPI('post', `${url}`, { machine_id: machineId, priceRule }),
            {
                retry: 0,
                onSuccess: () => {
                    setSuccessMessage('Machine Price Rule created successfully.');
                    queryClient.invalidateQueries([queryKey, machineId]); // Invalidate the cache to refetch
                },
                onError: (error) => {
                    const errorMessage = error?.error || 'An error occurred while creating Machine Price Rule.';
                    setErrorMessage(errorMessage);
                },
            }
        );

    return {
        getMachinePriceRules,
        getMachinePriceRuleById, // Changed to useQuery for fetching
        updateMachinePriceRule,
        deleteMachinePriceRule,
        postMachinePriceRule,
    };
};

export default useMachinePriceRulesHook;
