import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { confirmAlert } from 'react-confirm-alert';
import { useForm } from 'react-hook-form';
import useUsersHook from '../../../api/users';
import {
  Spinner,
  ViewUsers,
  Pagination,
  FormUsers,
  Message,
  Confirm, Search
} from '../../../components';

const Users = () => {
  const [page, setPage] = useState(1)
  const [id, setId] = useState(null)
  const [edit, setEdit] = useState(false)
  const [q, setQ] = useState('')

  const { getUsers, postUser, updateUser, deleteUser } = useUsersHook({
    page,
    q,
  })

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      confirmed: true,
      blocked: false,
    },
  })

  const { data, isLoading, isError, error, refetch } = getUsers

  const {
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
    mutateAsync: mutateAsyncUpdate,
  } = updateUser

  const {
    isLoading: isLoadingDelete,
    mutateAsync: mutateAsyncDelete,
  } = deleteUser

  const {
    isLoading: isLoadingPost,
    isSuccess: isSuccessPost,
    mutateAsync: mutateAsyncPost,
  } = postUser

  const formCleanHandler = () => {
    setEdit(false)
    reset()
  }

  useEffect(() => {
    if (isSuccessPost || isSuccessUpdate) formCleanHandler()
  }, [isSuccessPost, isSuccessUpdate])

  useEffect(() => {
    refetch()
  }, [page])

  useEffect(() => {
    if (!q) refetch()
  }, [q])

  const searchHandler = (e) => {
    e.preventDefault()
    refetch()
    setPage(1)
  }

  const deleteHandler = (id) => {
    confirmAlert(Confirm(() => mutateAsyncDelete(id)))
  }

  const submitHandler = (data) => {
    edit
      ? mutateAsyncUpdate({
          _id: id,
          name: data.name,
          email: data.email,
          confirmed: data.confirmed,
          blocked: data.blocked,
          password: data.password,
        })
      : mutateAsyncPost(data)
  }

  const editHandler = (user) => {
    setId(user._id)
    setEdit(true)
    setValue('name', user.name)
    setValue('email', user.email)
    setValue('confirmed', user.confirmed)
    setValue('blocked', user.blocked)
  }

  return (
      <>
        <HelmetProvider>
          <Helmet>
            <title>Users</title>
            <meta property='og:title' content='Users' key='title'/>
          </Helmet>
        </HelmetProvider>
        <div className='view-list-top-bar'>

          <h3 className='fw-light text-muted text-center mb-3'>
            Users List <sup className='fs-6'> [{data && data.total}] </sup>
          </h3>

          <div className='d-flex align-items-center justify-content-between mb-2'>
            <button
                className='btn add-new-entry-button align-self-end'
                data-bs-toggle='modal'
                data-bs-target='#userModal'
                onClick={() => formCleanHandler()}
            >
              Add New User
            </button>
            <Pagination data={data} setPage={setPage}/>
          </div>

          <div className='col-auto full-search'>
            <Search
                placeholder='Search by email'
                setQ={setQ}
                q={q}
                searchHandler={searchHandler}
            />
          </div>
        </div>

        {isLoading ? (
            <Spinner/>
        ) : isError ? (
            <Message variant='danger'>{error}</Message>
        ) : (
            <ViewUsers
                data={data}
                editHandler={editHandler}
                deleteHandler={deleteHandler}
                isLoadingDelete={isLoadingDelete}
            />
        )}
        <div className='pagination-bottom-block'>
          <Pagination data={data} setPage={setPage}/>
        </div>
        <FormUsers
            edit={edit}
            formCleanHandler={formCleanHandler}
            isLoading={isLoading}
            isError={isError}
            errors={errors}
            isLoadingUpdate={isLoadingUpdate}
            isLoadingPost={isLoadingPost}
            register={register}
            handleSubmit={handleSubmit}
            submitHandler={submitHandler}
            watch={watch}
            error={error}
        />
      </>
  )
}

export default Users
