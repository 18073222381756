import React, { createContext, useContext, useState } from 'react';
import AdminMessage from "../components/shared/AdminMessage";

const AdminMessageContext = createContext();

export const useMessage = () => useContext(AdminMessageContext);

export const AdminMessageProvider = ({ children }) => {
    const [message, setMessage] = useState(null);
    const [messageVariant, setMessageVariant] = useState('success');
    const [timeoutId, setTimeoutId] = useState(null);

    const setSuccessMessage = (msg) => {
        clearTimeout(timeoutId);
        setMessage(msg);
        setMessageVariant('success');
        const id = setTimeout(() => {
            setMessage(null);
        }, 5000);
        setTimeoutId(id);
    };

    const setErrorMessage = (msg) => {
        clearTimeout(timeoutId);
        setMessage(msg);
        setMessageVariant('danger');
        const id = setTimeout(() => {
            setMessage(null);
        }, 5000);
        setTimeoutId(id);
    };

    const clearMessage = () => {
        clearTimeout(timeoutId);
        setMessage(null);
    };

    return (
        <AdminMessageContext.Provider value={{ setSuccessMessage, setErrorMessage, clearMessage }}>
            {children}
            {message && <AdminMessage variant={messageVariant} onClose={clearMessage}>{message}</AdminMessage>}
        </AdminMessageContext.Provider>
    );
};
