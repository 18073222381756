import React, {useEffect, useState} from 'react';
import "../../../css/multi-input-machine.css";
import {useTranslation} from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import EntryEditFormMachines from './entryEditFormMachines';

export const inputGridOfEntriesBlock = ({dataProducts, watch, triggerReset, register, label, setValue, name , isRequired = false}) => {
    // console.log("dataProducts", dataProducts);
    const {i18n, t} = useTranslation();

    const [showBlockBox, setShowBlockBox] = useState(false);
    const [entries, setEntries] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [activeEntryInfo, setActiveEntryInfo] = useState(null);
    const [activeStyleClasses, setActiveStyleClasses] = useState({
        edit_mode: false,
        maximize_mode: false,
        simplify_mode: false,
    });
    useEffect(() => {
        // Action to perform when trigger changes
        console.log("Trigger received in child component:", triggerReset);
        setShowBlockBox(false);
        setEntries([]);
    }, [triggerReset]);

    const kiosk_external_id = watch('kiosk_external_id');

    const machineType = watch('machineType');
    // console.log(machineType);
    // Function to create a placeholder entry
    const createPlaceholderEntry = (stock, row, lane) => ({
        upc: '',
        current_stock: '0',
        max_stock: '0',
        motorType: '0',
        stock: stock.toString() ,
        row: row.toString(),
        lane: lane.toString(),
        merge: false,
        stepNum: "",
        price: "",
        imagePath: `${dataProducts.images_path}default_image.png`,
    });

    const togglePlanogramBox = () => {
        setShowBlockBox(!showBlockBox);

        safeParseJSON();
        // Additional actions when toggling the planogram box can be added here
    };

    // Function to ensure each row has 14 lanes, filling missing ones with placeholders
    const ensureCompleteLanes = (stock, row, lanes) => {
        const completeLanes = {};
        for (let i = 1; i <= 14; i++) {
            console.log('ADDING NEW LANE - stock' + stock  + ' row ' + row + ' lane ' + lanes[i])
            completeLanes[i] = lanes[i] || {
                // _id: uuidv4(), // Generate a unique ID for placeholders
                stock,
                row,
                lane: i,
                merge: false,
                placeholder: true, // Mark as a placeholder
                current_stock: '0',
                max_stock: '0',
                motorType: '0',
                stepNum: "",
                price: "",
                imagePath: `${dataProducts.images_path}default_image.png`,
            };
        }
        return completeLanes;
    };

    // const ensureCompleteRowsAndLanes = (groups, defaultImagePath) => {
    //     const maxRows = 7;
    //     Object.keys(groups).forEach(stock => {
    //         const existingRows = groups[stock];
    //         // Ensure 7 rows for each stock
    //         for (let row = 1; row <= maxRows; row++) {
    //             if (!existingRows[row]) {
    //                 existingRows[row] = {};
    //             }
    //             // Ensure each row has 9 lanes
    //             existingRows[row] = ensureCompleteLanes(stock, row, existingRows[row], defaultImagePath);
    //         }
    //     });
    // };

    const ensureStockAndCompleteLayout = (updatedEntries) => {
        // Determine required stocks based on machine type
        const requiredStocks = machineType === 'master-slave' ? [1, 2] : [1];

        // Ensure all required stocks exist
        requiredStocks.forEach(stock => {
            if (!updatedEntries[stock]) {
                updatedEntries[stock] = {};
            }
            for (let row = 1; row <= 7; row++) {
                if (!updatedEntries[stock][row]) {
                    updatedEntries[stock][row] = {};
                }
                for (let lane = 1; lane <= 14; lane++) { // Assuming each row should have 14 lanes
                    if (!updatedEntries[stock][row][lane]) {
                        console.log('CREATING PLACE HOLDER stock' + stock  + ' row ' + row + ' lane ' + lane)
                        updatedEntries[stock][row][lane] = createPlaceholderEntry(stock, row, lane);
                    }
                }
            }
        });

        setEntries(updatedEntries); // Update the state with the structured entries
    };



    const safeParseJSON = () => {
        try {
            // Attempt to retrieve and parse the JSON string from a watch function
            const jsonString = watch(name);
            if (jsonString) {
                // Parse the JSON string into an object
                let parsedData = JSON.parse(jsonString);

                // Reduce the parsed data into groups categorized by stock, then row, then lane
                const groups = parsedData.reduce((acc, entry) => {
                    const { stock, row, lane, upc } = entry;

                    // Initialize stock and row in accumulator if not already present
                    if (!acc[stock]) acc[stock] = {};
                    if (!acc[stock][row]) acc[stock][row] = {};

                    // Check if UPC is not set and mark entry as a placeholder if so
                    if (!upc || upc.trim() === "") {
                        console.log('Marking Entry as placeholder');
                        entry.placeholder = true; // Mark as a placeholder
                    }

                    // Find the product using the upc code to get the image path
                    const product = dataProducts.data.find(p => p.barcode === upc);
                    const imagePath = product && product.images.length > 0
                        ? `${dataProducts.images_path}${product.images[0].path}`
                        : `${dataProducts.images_path}default_image.png`; // Fallback to a default image if not found

                    // Assign the entry (with added image path) to the appropriate lane in the accumulator
                    acc[stock][row][lane] = { ...entry, imagePath };

                    return acc; // Return the updated accumulator for the next iteration
                }, {});

                // After all entries are processed, ensure that each stock has 7 rows,
                // and each row has 9 lanes, filling in placeholders as necessary
                ensureStockAndCompleteLayout(groups);

                // Update the state with the fully structured and completed groups
                setEntries(groups);
                return groups; // Return the groups for potential further use
            } else {
                return {}; // Return an empty object if jsonString is falsy
            }
        } catch (e) {
            // Log any errors encountered during the process
            console.error("Error parsing JSON: ", e);
            return {}; // Return an empty object in case of an error
        }
    };


    // Flatten function for submission with removal of _id for placeholder entries
    const flattenEntriesForSubmission = (entries) => {
        let flatEntries = [];

        // Iterate over each stock
        Object.entries(entries).forEach(([, rows]) => { // Removed unused 'stockId'
            // Iterate over each row in the stock
            Object.entries(rows).forEach(([, lanes]) => { // Removed unused 'rowId'
                // Iterate over each lane in the row
                Object.entries(lanes).forEach(([, entry]) => { // Removed unused 'laneId'
                    // Check if entry is a placeholder
                    if (entry.placeholder) {
                        // Create a new object without the _id property
                        const { _id, ...entryWithoutId } = entry; // ESLint warning for '_id' can be ignored or disabled for this line since it's being used to omit the property
                        flatEntries.push(entryWithoutId);
                        console.log("removed ID:" + _id);
                    } else {
                        // Add entry as is
                        flatEntries.push(entry);
                    }
                });
            });
        });

        return flatEntries;
    };



    // const addEntry = (current_stock, row) => {
    //     const tempId = uuidv4(); // Generate a unique ID
    //     const newEntry = {
    //         _id: tempId,
    //         upc: '',
    //         current_stock: current_stock,
    //         max_stock: '',
    //         motorType: '',
    //         stock: '',
    //         row: row,
    //         lane: '',
    //         stepNum: "",
    //         price: ""
    //     };
    //     const updatedEntries = [...entries, newEntry];
    //     setEntries(updatedEntries);
    //     setValue(name, JSON.stringify(updatedEntries));
    // };


    const handleInputChange = (stock, row, lane, id, e) => {
        console.log(stock);
        setEntries(currentEntries => {
            // Creating a new structure to avoid direct mutations
            const updatedEntries = { ...currentEntries };

            // Check if the stock exists, if not, create an empty structure
            if (!updatedEntries[stock]) {
                updatedEntries[stock] = {};
            }

            // Check if the row exists within the stock, if not, create an empty structure
            if (!updatedEntries[stock][row]) {
                updatedEntries[stock][row] = {};
            }

            // Whether we're updating an existing entry or creating a new one
            let entryToUpdate = updatedEntries[stock][row][lane];

            // If the entry exists and IDs match, or we're creating a new one
            if (entryToUpdate?._id === id || !entryToUpdate) {
                // If the entry doesn't exist, initialize it
                if (!entryToUpdate) {
                    entryToUpdate = {
                        _id: uuidv4(), // Generate a new ID if we're creating a new entry
                        stock,
                        row,
                        lane, // Include stock, row, and lane information
                        // Default values for other fields could go here
                    };
                }

                entryToUpdate.placeholder = false;


                // GET DATA FROM INPUT TARGET - For checkboxes, use 'checked' property; for others, use 'value'
                const isCheckbox = e.target.type === 'checkbox';
                entryToUpdate[e.target.name] = isCheckbox ? e.target.checked : e.target.value;

                if (e.target.name === "merge" && e.target.checked) {
                    entryToUpdate.motorType = "2"; // Set motorType to '2' if merge is checked

                    // If lane > 1, get data from the previous lane
                    if (lane > 1) {
                        const previousLaneData = updatedEntries[stock][row][lane - 1];
                        if (previousLaneData) {
                            // Assign values from previous lane data to the current entryToUpdate
                            entryToUpdate.upc = previousLaneData.upc;
                            entryToUpdate.stepNum = previousLaneData.stepNum;
                            entryToUpdate.current_stock = previousLaneData.current_stock;
                            entryToUpdate.max_stock = previousLaneData.max_stock;
                            entryToUpdate.price = previousLaneData.price;
                        }
                    }
                } else if (e.target.name === "merge" && !e.target.checked) {
                    entryToUpdate.motorType = 1; // Set motorType back to '1' if merge is unchecked
                }



                // If updating 'upc', handle imagePath update
                if (e.target.name === "upc") {

                    const selectedProduct = dataProducts.data.find(product => product.barcode === e.target.value);
                    const imagePath = selectedProduct && selectedProduct.images.length > 0
                        ? `${dataProducts.images_path}${selectedProduct.images[0].path}`
                        : `${dataProducts.images_path}default_image.png`;
                    entryToUpdate.imagePath = imagePath;
                }

                // Assign the updated or new entry back to the structure
                updatedEntries[stock][row][lane] = entryToUpdate;
            } else {
                console.error('ID mismatch and existing entry not found for update.');
                // Handle the mismatch case as needed
            }

            // Flatten entries just for this submission
            const submissionEntries = flattenEntriesForSubmission(entries);

            setValue(name, JSON.stringify(submissionEntries)); // Optionally update the form field
            // setValue(name, JSON.stringify(updatedEntries)); // Optionally update the form field
            return updatedEntries;
        });
    };

    const deleteEntry = (stock, row, laneKey, idValue) => {
        // Deep copy the entries to avoid mutating state directly
        let newEntries = JSON.parse(JSON.stringify(entries));

        // Check if the targeted entry exists and matches the provided ID before deleting
        const targetEntry = newEntries[stock]?.[row]?.[laneKey];
        if (targetEntry && targetEntry._id === idValue) {
            // Delete the entry directly
            delete newEntries[stock][row][laneKey];

            // Recalculate lanes to ensure completeness
            newEntries[stock][row] = ensureCompleteLanes(stock, row, newEntries[stock][row]);

            // Optionally, clean up the structure if the row or stock becomes empty
            // if (Object.keys(newEntries[stock][row]).length === 0) {
            //     delete newEntries[stock][row];
            // }
            // if (Object.keys(newEntries[stock]).length === 0) {
            //     delete newEntries[stock];
            // }
        } else {
            console.log('Entry not found for delete ' + idValue);
        }

        // Update the state and form field
        setEntries(newEntries);
        // setValue(name, JSON.stringify(newEntries));

        // Flatten entries just for this submission
        const submissionEntries = flattenEntriesForSubmission(entries);

        setValue(name, JSON.stringify(submissionEntries)); // Optionally update the form field
    };


    const openModalForLane = (stock, row, lane, id) => {
        setActiveEntryInfo({ stock, row, lane, id });
        setModalOpen(true);
    };

    // Handle selecting a product from the modal
    const handleProductSelect = (selectedProduct) => {
        if (activeEntryInfo && selectedProduct) {
            setEntries((currentEntries) => {
                const updatedEntries = { ...currentEntries };
                const { stock, row, lane, id } = activeEntryInfo;

                // Check and ensure the path exists in the structure
                if (updatedEntries[stock] && updatedEntries[stock][row] && updatedEntries[stock][row][lane] && updatedEntries[stock][row][lane]._id === id) {
                    // Update the entry with the selected product's barcode and imagePath
                    updatedEntries[stock][row][lane].upc = selectedProduct.barcode;
                    updatedEntries[stock][row][lane].imagePath = selectedProduct.images.length > 0 ? `${dataProducts.images_path}${selectedProduct.images[0].path}` : `${dataProducts.images_path}default_image.png`;
                }

                // Return the updated entries
                return updatedEntries;
            });

            // After updating, reset modal state and active entry info
            setModalOpen(false);
            setActiveEntryInfo(null);
        }
    };

    // Function to toggle the class based on the button click
    const toggleStyleMode = (mode) => {
        setActiveStyleClasses((prevState) => ({
            ...prevState, // Spread the previous state
            [mode]: !prevState[mode], // Toggle the boolean value for the specific mode
        }));
    };
    // Generating the class string based on the active styles
    const modeClasses = Object.entries(activeStyleClasses)
        .filter(([, isActive]) => isActive) // Notice the omission of '_' and direct use of ','
        .map(([mode]) => mode)
        .join(' ');

    // Toggle Stock View
    const toggleStockVisibility = (event) => {
        const stockGroup = event.currentTarget.parentNode;
        stockGroup.classList.toggle('hide-stock-rows');
    };

    const toggleRowVisibility = (event) => {
        const stockGroup = event.currentTarget.parentNode;
        stockGroup.classList.toggle('show-row-details');
    };

    function determineEntryClass(entry) {
        console.log(entry);
        // If the entry is marked to be merged and has a valid UPC, it is not considered problematic
        if (entry.placeholder) {
            // console.log(entry.upc + ' placeholder');
            return '';
        }
        if (!entry.upc) {
            console.log(entry.upc + ' problematic-data-upc');
            return 'problematic-data';
        }

        if (entry.merge) {
            // console.log(entry.upc + ' merge');
            return '';
        }

        // Check if UPC exists and any of the specified fields are empty, non-existent, or zero
        if ( !entry.stepNum || entry.stepNum === 0 || !entry.max_stock || entry.max_stock === 0 )
        {
            return 'problematic-data';
        }

        // Check if UPC exists and any of the specified fields are empty, non-existent, or zero
        if ( !entry.current_stock ||entry.current_stock === 0 )
        {
            return 'no-stock';
        }

        return '';
    }


    // Check if dataProducts is loaded
    if (!dataProducts || !Array.isArray(dataProducts.data) || dataProducts.data.length === 0) {
        return <div>Loading products...</div>;
    }

    const entriesValue = watch(name)
    // console.log('nameInitial:', entriesValue);

    return (
        <div className='items-selector-box'>

            <div className='mb-3 items-input'>
                <label htmlFor={name}>Selected Items</label>
                <textarea
                    {...register('items', isRequired && {required: `${label} is required`})}
                    className='form-control'
                    id={name}
                    name={name}
                    style={{height: '10px',color: '#9f9f9f'}}
                    value={entriesValue}
                    // onChange={handleTextareaChange}
                />
            </div>


            <button className='entries-block-box-button' onClick={togglePlanogramBox} type="button">
                {showBlockBox ? 'Hide Planogram Editor' : 'Edit Planogram'}
            </button>

            {/*{console.log(entries)}*/}
            {showBlockBox && (
                <div id={`external-id-${kiosk_external_id}`} className={`entries-block-box ${modeClasses}`} >

                    <div className='planogram-style-actions'>
                        <button className='planogram-style-button details'
                                onClick={() => toggleStyleMode('details_mode')}
                                type="button">Details
                        </button>
                        <button className='planogram-style-button maximize'
                                onClick={() => toggleStyleMode('maximize_mode')}
                                type="button">Maximize
                        </button>
                        {/*<button className='planogram-style-button simplify'*/}
                        {/*        onClick={() => toggleStyleMode('simplify_mode')}*/}
                        {/*        type="button">Simplify*/}
                        {/*</button>*/}
                    </div>

                    {modalOpen && (
                        <EntryEditFormMachines
                            isOpen={modalOpen}
                            onRequestClose={() => setModalOpen(false)}
                            products={dataProducts.data}
                            onSelect={handleProductSelect}
                            i18n={i18n}
                            t={t}
                        />
                    )}


                    {Object.entries(entries).map(([stock, rows]) => (
                        <div key={stock} className='entries-stock-group' data-stock={stock}>
                            <div className='entries-stock-title' onClick={toggleStockVisibility}>{t("Storage")} {stock}</div>
                            <div className='entries-stock-rows'>
                            {Object.entries(rows).sort(([rowA], [rowB]) => Number(rowB) - Number(rowA)).map(([row, lanes]) => (
                                    <div key={row} className="entries-row-group" data-row={row}>
                                        <div className='entries-row-title' onClick={toggleRowVisibility}>{t("Row")} {row}</div>
                                            {Object.entries(lanes).map(([lane, entry]) => (
                                                <div key={entry._id}
                                                     className={`entry-inputs-block ${determineEntryClass(entry)}`}
                                                     data-lane={lane}
                                                     data-keyEntry={entry._id}
                                                     data-placeholder={entry.placeholder}
                                                     data-merge={entry.merge}
                                                >

                                                    <div className="list-item-input-thumbnail">
                                                        <img src={entry.imagePath}
                                                             alt="Selected Product"
                                                             onClick={() => openModalForLane(stock, row, entry.lane, entry._id)}
                                                        />
                                                    </div>
                                                    <div className='upc-entry-lane-number'>{lane}</div>
                                                    <div className='upc-entry-input-dropdown'>
                                                        <input className='upc-entry-input-click-dropdown' type="text"
                                                               name="upc"
                                                               value={entry.upc}
                                                               placeholder="Click to select product..."
                                                               readOnly
                                                            // onClick={() => openModalForLane(stock, row, entry.lane, entry._id)}
                                                            // onChange={(e) => handleInputChange(stock, row, entry.lane, entry._id, e)}
                                                            // onChange={(e) => handleInputChange(stock, row, entry.lane, entry._id, e)}
                                                        />

                                                        {/*<input className='upc-entry-input-click-dropdown' type="text"*/}
                                                        {/*       name="upc"*/}
                                                        {/*       placeholder="UPC"*/}
                                                        {/*       value={entry.upc}*/}
                                                        {/*       onChange={(e) => handleInputChange(stock, row, entry.lane, entry._id, e)}/>*/}

                                                        {/*<select className='upc-entry-input-select-dropdown'*/}
                                                        {/*        name="upc"*/}
                                                        {/*        value={entry.upc}*/}
                                                        {/*        onChange={(e) => handleInputChange(stock, row, entry.lane, entry._id, e)}*/}
                                                        {/*>*/}
                                                        {/*    /!* Placeholder option *!/*/}
                                                        {/*    <option value="">Select a product...</option>*/}
                                                        {/*    {dataProducts.data.map(product => (*/}
                                                        {/*        <option key={product._id} value={product.barcode}>*/}
                                                        {/*            {product.name?.[i18n.language] ?? product.name?.el} -*/}
                                                        {/*            ({product.barcode})*/}
                                                        {/*        </option>*/}
                                                        {/*    ))}*/}
                                                        {/*</select>*/}
                                                    </div>
                                                    <div className='entries-input-rows'>

                                                        <div className='entry-input-row-data readonly-data'>
                                                            <div className='entry-input-row-data-title'>Storage:</div>
                                                            <input type="number"
                                                                   name="stock"
                                                                   placeholder="Stock"
                                                                   value={entry.stock}
                                                                   readOnly={true}
                                                            />
                                                        </div>
                                                        <div className='entry-input-row-data readonly-data'>
                                                            <div className='entry-input-row-data-title'>Row:</div>
                                                            <input type="number"
                                                                   name="row"
                                                                   min="0"
                                                                   placeholder="Row"
                                                                   value={entry.row}
                                                                   readOnly={true}
                                                            />
                                                        </div>
                                                        <div className='entry-input-row-data readonly-data'>
                                                            <div className='entry-input-row-data-title'>Lane:</div>
                                                            <input type="number"
                                                                   min="0"
                                                                   name="lane"
                                                                   placeholder="Lane"
                                                                   value={entry.lane}
                                                                   readOnly={true}
                                                            />
                                                        </div>
                                                        <div className='entry-input-row-data'>
                                                            <div className='entry-input-row-data-title'>Motor:</div>
                                                            <input type="number"
                                                                   name="motorType"
                                                                   placeholder="motor"
                                                                   value={entry.motorType}
                                                                   min="0"
                                                                   max="12"
                                                                   onChange={(e) => handleInputChange(stock, row, entry.lane, entry._id, e)}/>
                                                        </div>
                                                        <div className='entry-input-row-data'>
                                                            <div className='entry-input-row-data-title'>Steps:</div>
                                                            <input type="number" name="stepNum" placeholder="steps"
                                                                   value={entry.stepNum}
                                                                   min="0"
                                                                   onChange={(e) => handleInputChange(stock, row, entry.lane, entry._id, e)}/>
                                                        </div>
                                                        <div className='entry-input-row-data'>
                                                            <div className='entry-input-row-data-title'>Current Stock:
                                                            </div>
                                                            <input type="number" name="current_stock"
                                                                   placeholder="Current Stock"
                                                                   min="0"
                                                                   value={entry.current_stock}
                                                                   onChange={(e) => handleInputChange(stock, row, entry.lane, entry._id, e)}/>
                                                        </div>
                                                        <div className='entry-input-row-data'>
                                                            <div className='entry-input-row-data-title'>Max Stock:</div>
                                                            <input type="number" name="max_stock"
                                                                   placeholder="Max Stock"
                                                                   value={entry.max_stock}
                                                                   min="0"
                                                                   onChange={(e) => handleInputChange(stock, row, entry.lane, entry._id, e)}/>
                                                        </div>
                                                        <div className='entry-input-row-data'>
                                                            <div className='entry-input-row-data-title'>Price:</div>
                                                            <input type="text" name="price" placeholder="price"
                                                                   value={entry.price}
                                                                   onChange={(e) => handleInputChange(stock, row, entry.lane, entry._id, e)}/>
                                                        </div>
                                                        <div className='entry-input-row-data'>
                                                            <div className='entry-input-row-data-title'>Expiration:</div>
                                                            <input type="text" name="expiration" placeholder="expiration"
                                                                   value={entry.expiration_date}
                                                                   onChange={(e) => handleInputChange(stock, row, entry.lane, entry._id, e)}/>
                                                        </div>
                                                    </div>
                                                    <div className='entry-input-merge'>
                                                        <div className='entry-input-row-data-title'>Merge:</div>
                                                        <input className='form-check-input'
                                                               type="checkbox"
                                                               name="merge"
                                                               placeholder="merge"
                                                               value={entry.merge}
                                                               onClick={(e) => handleInputChange(stock, row, entry.lane, entry._id, e)}/>
                                                    </div>
                                                    <button className='entry-delete-button'
                                                            onClick={() => deleteEntry(stock, row, entry.lane, entry._id)}
                                                            type="button">
                                                        {t('Delete')}
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}


export default inputGridOfEntriesBlock;