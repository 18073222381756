import {Spinner, Message} from '../../index'
import {
    dynamicInputSelect,
    inputText,
} from '../../../utils/dynamicForm';

export const FormOrders = ({
                               edit,
                               formCleanHandler,
                               isLoading,
                               register,
                               isError,
                               isErrorUpdate,
                               errors,
                               isLoadingUpdate,
                               isLoadingPost,
                               handleSubmit,
                               submitHandler,
                               error,
                               dataMachines,
                               watch
                           }) => {

    // console.log(getDefaultCategoryOptions());
    // const categoryOptions = getDefaultCategoryOptions().map((option, index) => ({
    //   ...option,
    //   key: index.toString(),
    // }));
    const orderItems = watch('orderItems'); // Use watch to access orderItems
    const machineCurrentCash = watch('machine_current_cash');

    console.log('formOrders' );
    console.log( dataMachines);

    return (
        <div
            className='modal fade'
            id='orderModal'
            data-bs-backdrop='static'
            data-bs-keyboard='false'
            tabIndex='-1'
            aria-labelledby='orderModalLabel'
            aria-hidden='true'
        >
            <div className='modal-dialog'>
                <div className='modal-content modal-background'>
                    <div className='modal-header'>
                        <h3 className='modal-title ' id='orderModalLabel'>
                            {edit ? 'Edit Orders' : 'Add a NEW Order'}
                        </h3>
                        <button
                            type='button'
                            className='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                            onClick={formCleanHandler}
                        ></button>
                    </div>
                    <div className='modal-body'>
                        {isLoading ? (
                            <Spinner/>
                        ) : isError ? (
                            <Message variant='danger'>{error}</Message>
                        ) : isErrorUpdate ? (
                            <Message variant='danger'>{error}</Message>
                        ) : (
                            <form onSubmit={handleSubmit(submitHandler)}>
                                {inputText({
                                    register,
                                    errors,
                                    label: 'Order ID',
                                    name: 'order_id',
                                    placeholder: '',
                                    isRequired: true,
                                })}
                                {/*{inputText({*/}
                                {/*    register,*/}
                                {/*    errors,*/}
                                {/*    label: 'Machine ID',*/}
                                {/*    name: 'machine',*/}
                                {/*    placeholder: '',*/}
                                {/*    isRequired: true,*/}
                                {/*})}*/}

                                {dynamicInputSelect({
                                    register,
                                    errors,
                                    label: 'Machine',
                                    name: 'machine',
                                    value: 'kiosk_external_id',
                                    data:
                                        dataMachines &&
                                        dataMachines.data,
                                    placeholder: 'Machine',
                                })}
                                <h5>Order Items:</h5>
                                {orderItems?.length > 0 ? (
                                    <ul className="list-group">
                                        {orderItems.map((item, index) => (
                                            <li key={item._id || index} className="list-group-item">
                                                <strong>Product ID:</strong> {item.productId} <br />
                                                <strong>Barcode:</strong> {item.barcode} <br />
                                                <strong>Quantity:</strong> {item.quantity} <br />
                                                <strong>Item Price:</strong> {item.itemPrice} <br />
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>No items in this order.</p>
                                )}
                                <h5>CASH STOCK:</h5>
                                {machineCurrentCash ? (
                                    <ul className="list-group">
                                        {/* Credit */}
                                        <li className="list-group-item">
                                            <strong>Credit:</strong> {machineCurrentCash.credit}
                                        </li>

                                        {/* Max Change */}
                                        <li className="list-group-item">
                                            <strong>Max Change:</strong> {machineCurrentCash.max_change}
                                        </li>

                                        {/* Bills Stock */}
                                        <li className="list-group-item">
                                            <strong>Bills Stock:</strong>
                                            <ul>
                                                {machineCurrentCash.bills_stock.map((bill, index) => (
                                                    <li key={index}>
                                                        <strong>Amount:</strong> {bill.amount} <br />
                                                        <strong>Stock:</strong> {bill.stock === 1 ? 'Enabled' : 'Disabled'}
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>

                                        {/* Coins Total Stock */}
                                        <li className="list-group-item">
                                            <strong>Coins Total Stock:</strong> {machineCurrentCash.coins_total_stock}
                                        </li>
                                    </ul>
                                ) : (
                                    <p>No cash stock data available.</p>
                                )}

                                {inputText({
                                    register,
                                    errors,
                                    label: 'PaymentMethod',
                                    name: 'paymentMethod',
                                    placeholder: '',
                                    isRequired: false,
                                })}
                                {inputText({
                                    register,
                                    errors,
                                    label: 'totalQuantity',
                                    name: 'totalQuantity',
                                    placeholder: '',
                                    isRequired: false,
                                })}
                                {inputText({
                                    register,
                                    errors,
                                    label: 'totalPrice',
                                    name: 'totalPrice',
                                    placeholder: '',
                                    isRequired: false,
                                })}
                                {inputText({
                                    register,
                                    errors,
                                    label: 'cartState',
                                    name: 'cartState',
                                    placeholder: '',
                                    isRequired: false,
                                })}
                                {inputText({
                                    register,
                                    errors,
                                    label: 'orderTime',
                                    name: 'orderTime',
                                    placeholder: '',
                                    isRequired: false,
                                })}
                                {inputText({
                                    register,
                                    errors,
                                    label: 'transactionStatus',
                                    name: 'transactionStatus',
                                    placeholder: '',
                                    isRequired: false,
                                })}
                                {inputText({
                                    register,
                                    errors,
                                    label: 'transactionStatusMessage',
                                    name: 'transactionStatusMessage',
                                    placeholder: '',
                                    isRequired: false,
                                })}
                                {inputText({
                                    register,
                                    errors,
                                    label: 'synced_status',
                                    name: 'synced_status',
                                    placeholder: '',
                                    isRequired: false,
                                })}
                                {inputText({
                                    register,
                                    errors,
                                    label: 'synced_id',
                                    name: 'synced_id',
                                    placeholder: '',
                                    isRequired: false,
                                })}
                                {inputText({
                                    register,
                                    errors,
                                    label: 'synced_at',
                                    name: 'synced_at',
                                    placeholder: '',
                                    isRequired: false,
                                })}
                                {inputText({
                                    register,
                                    errors,
                                    label: 'qr_code_data',
                                    name: 'qr_code_data',
                                    placeholder: '',
                                    isRequired: false,
                                })}
                                {inputText({
                                    register,
                                    errors,
                                    label: 'qr_code_link',
                                    name: 'qr_code_link',
                                    placeholder: '',
                                    isRequired: false,
                                })}
                                {inputText({
                                    register,
                                    errors,
                                    label: 'synced_at',
                                    name: 'synced_at',
                                    placeholder: '',
                                    isRequired: false,
                                })}
                                <div className='modal-footer'>
                                    <button
                                        type='button'
                                        className='btn btn-secondary '
                                        data-bs-dismiss='modal'
                                        onClick={formCleanHandler}
                                    >
                                        Close
                                    </button>
                                    <button
                                        type='submit'
                                        className='btn btn-primary '
                                        disabled={isLoadingPost || isLoadingUpdate}
                                    >
                                        {isLoadingPost || isLoadingUpdate ? (
                                            <span className='spinner-border spinner-border-sm'/>
                                        ) : (
                                            'Submit'
                                        )}
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormOrders
