// THIS IS A HOOK TO GRAB GET DATA FROM THE API WITH MINIMAL INFORMATION
// SOME TIMES WE JUST NEED FOR EXAMPLE JUST THE NAME AND THE ID OF THE MACHINE AND NOT THE INVENTORY

import dynamicAPI from './dynamicAPI'
import {useQuery} from 'react-query'

const usersUrl = '/api/auth/min/users'
const machinesUrl = '/api/auth/min/machines'

export default function useSimpleDataHook(props) {
    const {page = 1, q = '', limit = 25} = props

    const getSimpleMachines = useQuery(
        "simpleUsers",
        async () =>
            await dynamicAPI('get', `${machinesUrl}?page=${page}&q=${q}&limit=${limit}`, {}),
        {retry: 0}
    )

    const getSimpleUsers = useQuery(
        "simpleMachines",
        async () =>
            await dynamicAPI('get', `${usersUrl}?page=${page}&q=${q}&limit=${limit}`, {}),
        {retry: 0}
    )

    return {
        getSimpleMachines,
        getSimpleUsers
    }
}
