import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import './css/index.css'
import Admin from './Admin'
import Kiosk from './Kiosk'
import "./utils/i18n";
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import NotFound from "./screens/auth/NotFound";

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
              <Routes>
                <Route path='/kiosk/*' element={<Kiosk />} />
                <Route path='/admin/*' element={<Admin />} />
                 {/* NOT FOUND ROUTE*/}
                <Route path='*' element={<NotFound/>}/>
              </Routes>
      </BrowserRouter>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>
)
