import dynamicAPI from './dynamicAPI';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useMessage } from '../context/AdminMessageContext';

const url = '/api/auth/roles';
const queryKey = 'roles';

export default function useRolesHook(props) {
    const { page = 1, id, q = '', limit = 25 } = props;
    const queryClient = useQueryClient();
    const { setSuccessMessage, setErrorMessage } = useMessage(); // Use the message context

    const getRoles = useQuery(
        [queryKey, page, limit],
        async () =>
            await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}`, {}),
        { retry: 0 }
    );

    const getRoleById = useQuery(
        [queryKey, id],
        async () => await dynamicAPI('get', `${url}/${id}`, {}),
        { retry: 0, enabled: !!id }
    );

    const updateRole = useMutation(
        async (obj) => await dynamicAPI('put', `${url}/${obj._id}`, obj),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('Role updated successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to update role.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while updating the role.';
                setErrorMessage(errorMessage);
            }
        }
    );

    const deleteRole = useMutation(
        async (id) => await dynamicAPI('delete', `${url}/${id}`, {}),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('Role deleted successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to delete role.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while deleting the role.';
                setErrorMessage(errorMessage);
            }
        }
    );

    const postRole = useMutation(
        async (obj) => await dynamicAPI('post', url, obj),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('Role created successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to create role.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while creating the role.';
                setErrorMessage(errorMessage);
            }
        }
    );

    return {
        getRoles,
        updateRole,
        deleteRole,
        postRole,
        getRoleById
    };
}
