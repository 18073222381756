import dynamicAPI from './dynamicAPI';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useMessage } from '../context/AdminMessageContext';

const url = '/api/auth/machines';
const queryKey = 'Machines';

export default function useMachinesHook(props) {
    const { page = 1, id, q = '', limit = 25 } = props;
    const queryClient = useQueryClient();
    const { setSuccessMessage, setErrorMessage } = useMessage(); // Use the message context

    const getMachines = useQuery(
        [queryKey, page, limit],
        async () => {
            const response = await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}`);
            if (response.isError) throw new Error(response.error);
            return response;
        },
        { retry: 0 }
    );

    const getMachineById = useQuery(
        [queryKey, id],
        async () => {
            const response = await dynamicAPI('get', `${url}/${id}`);
            if (response.isError) throw new Error(response.error);
            return response;
        },
        { retry: 0, enabled: !!id }
    );

    const updateMachine = useMutation(
        async (obj) => {
            const response = await dynamicAPI('put', `${url}/${obj._id}`, obj);
            if (response.isError) throw new Error(response.error);
            return response;
        },
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('Machine updated successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to update machine.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error.message || 'An error occurred while updating the machine.';
                setErrorMessage(errorMessage);
            },
        }
    );

    const deleteMachine = useMutation(
        async (id) => {
            const response = await dynamicAPI('delete', `${url}/${id}`);
            if (response.isError) throw new Error(response.error);
            return response;
        },
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('Machine deleted successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to delete machine.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error.message || 'An error occurred while deleting the machine.';
                setErrorMessage(errorMessage);
            },
        }
    );

    const postMachine = useMutation(
        async (obj) => {
            const response = await dynamicAPI('post', url, obj);
            if (response.isError) throw new Error(response.error);
            return response;
        },
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('Machine created successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to create machine.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error.message || 'An error occurred while creating the machine.';
                setErrorMessage(errorMessage);
            },
        }
    );

    const getMachineTotalStockById = async (machineId) => {
        const response = await dynamicAPI('get', `${url}/totalStock/${machineId}`);
        if (response.isError) throw new Error(response.error);
        return response;
    };

    const getMachineTotalPriceById = async (machineId) => {
        const response = await dynamicAPI('get', `${url}/totalPrice/${machineId}`);
        if (response.isError) throw new Error(response.error);
        return response;
    };

    return {
        getMachines,
        updateMachine,
        deleteMachine,
        postMachine,
        getMachineById,
        getMachineTotalStockById,
        getMachineTotalPriceById,
    };
}
