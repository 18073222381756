import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useUserProfilesHook from '../../../api/profiles';
import ViewUserProfiles from '../../../components/admin/user-profiles/ViewUserProfiles';
import { Spinner, Pagination, Message, Search } from '../../../components';
import {useTranslation} from "react-i18next";
const UserProfiles = () => {
  const [page, setPage] = useState(1)
  const [q, setQ] = useState('')

  const { getUserProfiles } = useUserProfilesHook({
    page,
    q
  })
  const { t} = useTranslation(); // Use i18n instance to access current language

  const { data, isLoading, isError, error, refetch } = getUserProfiles;

  useEffect(() => {
    refetch()
  }, [page])

  useEffect(() => {
    if (!q) refetch()
  }, [q])

  const searchHandler = (e) => {
    e.preventDefault()
    refetch()
    setPage(1)
  }

  return (
      <>
        <HelmetProvider>
          <Helmet>
            <title>User Profiles</title>
            <meta property='og:title' content='User Profiles' key='title'/>
          </Helmet>
        </HelmetProvider>


        <div className='view-list-top-bar'>

          <h3 className='fw-light text-muted text-center mb-3'>
            {t("UserProfiles List")} <sup className='fs-6'> [{data && data.total}] </sup>
          </h3>

          <div className='d-flex align-items-center justify-content-between mb-2 full-width-pagination'>
            <Pagination data={data} setPage={setPage}/>
          </div>

          <div className='col-auto full-search'>
            <Search
                placeholder={t("Search by Name")}
                setQ={setQ}
                q={q}
                searchHandler={searchHandler}
            />
          </div>
        </div>

        {isLoading ? (
            <Spinner/>
        ) : isError ? (
            <Message variant='danger'>{error}</Message>
        ) : (
            <ViewUserProfiles
                data={data}
            />
        )}
        <div className='pagination-bottom-block'>
          <Pagination data={data} setPage={setPage}/>
        </div>
      </>
  )
}

export default UserProfiles
