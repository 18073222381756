import React from "react";

// import {useLocation } from "react-router-dom";
// import SearchBar from "../SearchBar/SearchBar";
import LanguageSwitcherDropdown from "../shared/LanguageSwitcher";

function Header({ machine_id }) {
  // const location = useLocation();
  // const { pathname } = location;

    const handleLogoClick = () => {
        window.location.href = `/kiosk/machine/${machine_id}`;
    };

  return (
    <header className="kiosk-header">
      <nav className="kiosk-nav">
          <img
              width='160'
              height='160'
              src='/img/imall_logo_nobg.png'
              className='img-fluid brand-logos'
              alt='logo'
              onClick={handleLogoClick}
          />

        {/*<div className="search_header">{pathname === "/" && <SearchBar />}</div>*/}
      </nav>
      <LanguageSwitcherDropdown />
    </header>
  );
}

export default Header;
