import Navigation from './Navigation'
import Footer from './Footer'

export default function Layout({children, showAdminNavigation = false}) {
    return (
        <>
            {/* <Helmet>
                <title>React Boilerplate</title>
                <meta property='og:title' content='React Boilerplate' key='title' />
              </Helmet> */}

            {showAdminNavigation && <Navigation/>}
            <main className='container py-2' style={{minHeight: '70vh'}}>
                {children}
            </main>
            {showAdminNavigation && <Footer/>}
        </>
    )
}
