import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { FormContainer, Spinner, Message } from '../../../components';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useMachinePriceRulesHook from '../../../api/machinePriceRules';
import {
    inputText,
    inputNumber,
    inputDate,
    inputCheckBox
} from '../../../utils/dynamicForm';

// Helper to parse query parameters
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const MachinePriceRules = () => {
    const query = useQuery();
    const machineId = query.get('machine_id'); // Extract machine_id from query parameters

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm();

    const [selectedRule, setSelectedRule] = useState(null);
    const {
        getMachinePriceRuleById,
        postMachinePriceRule,
        updateMachinePriceRule,
        deleteMachinePriceRule
    } = useMachinePriceRulesHook();

    // Fetch the price rules for the specific machineId
    const { data: machineRules, isLoading, isError, error } = getMachinePriceRuleById(machineId);
    const { mutateAsync: createPriceRule, isLoading: isLoadingPost } = postMachinePriceRule;
    const { mutateAsync: updatePriceRule, isLoading: isLoadingUpdate } = updateMachinePriceRule;
    const { mutateAsync: removePriceRule } = deleteMachinePriceRule;

    // Populate form if editing a price rule
    useEffect(() => {
        if (selectedRule) {
            setValue('ruleName', selectedRule.ruleName);
            setValue('discountPercentage', selectedRule.discountPercentage);
            setValue('startDate', selectedRule.startDate.split('T')[0]);
            setValue('endDate', selectedRule.endDate.split('T')[0]);
            setValue('active', selectedRule.active);
        } else {
            reset();
        }
    }, [selectedRule, setValue, reset]);

    // Handle form submission
    const submitHandler = async (data) => {
        if (selectedRule) {
            await updatePriceRule({
                machineId,
                ruleId: selectedRule._id,
                updatedData: data,
            });
        } else {
            await createPriceRule({
                machine_id: machineId,
                priceRule: data,
            });
        }
        reset();
        setSelectedRule(null);
    };

    // Handle rule deletion
    const deleteHandler = async (ruleId) => {
        await removePriceRule({ machineId, ruleId });
    };

    return (
        <FormContainer>
            <HelmetProvider>
                <Helmet>
                    <title>Machine Price Rules</title>
                    <meta property="og:title" content="Machine Price Rules" key="title" />
                </Helmet>
            </HelmetProvider>
            <h3 className="fw-light font-monospace text-center">Manage Machine Price Rules</h3>

            {isLoading && <Spinner />}
            {isError && <Message variant="danger">{error.message}</Message>}

            <form onSubmit={handleSubmit(submitHandler)}>
                <div className="row">
                    <div className="col-12">
                        {inputText({
                            register,
                            errors,
                            label: 'Rule Name',
                            name: 'ruleName',
                            placeholder: 'Enter rule name',
                            isRequired: true,
                        })}
                    </div>
                    <div className="col-md-6 col-12">
                        {inputNumber({
                            register,
                            errors,
                            label: 'Discount Percentage',
                            name: 'discountPercentage',
                            placeholder: 'Enter discount (%)',
                            isRequired: true,
                        })}
                    </div>
                    <div className="col-md-6 col-12">
                        {inputDate({
                            register,
                            errors,
                            label: 'Start Date',
                            name: 'startDate',
                            isRequired: true,
                        })}
                    </div>
                    <div className="col-md-6 col-12">
                        {inputDate({
                            register,
                            errors,
                            label: 'End Date',
                            name: 'endDate',
                            isRequired: true,
                        })}
                    </div>
                    <div className="col-12">
                        {inputCheckBox({
                            register,
                            errors,
                            label: 'Active',
                            name: 'active',
                            placeholder: '',
                            isRequired: false,
                        })}
                    </div>
                </div>

                <button type="submit" className="btn btn-primary form-control" disabled={isLoadingPost || isLoadingUpdate}>
                    {isLoadingPost || isLoadingUpdate ? (
                        <span className="spinner-border spinner-border-sm" />
                    ) : (
                        selectedRule ? 'Update Rule' : 'Add Rule'
                    )}
                </button>
            </form>

            <div className="mt-4">
                <h5 className="text-center">Existing Price Rules</h5>
                <ul className="list-group">
                    {machineRules?.map((rule) => (
                        <li className="list-group-item d-flex justify-content-between align-items-center" key={rule._id}>
                            <span>{rule.ruleName} - {rule.discountPercentage}%</span>
                            <div>
                                <button className="btn btn-warning btn-sm mx-2" onClick={() => setSelectedRule(rule)}>
                                    Edit
                                </button>
                                <button className="btn btn-danger btn-sm" onClick={() => deleteHandler(rule._id)}>
                                    Delete
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </FormContainer>
    );
};

export default MachinePriceRules;
