import React, {useState, useEffect} from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {confirmAlert} from 'react-confirm-alert';
import {useForm} from 'react-hook-form';
import useUserMachinesHook from '../../../api/userMachines';
import ViewUserMachines from '../../../components/admin/userMachines/ViewUserMachines';
import FormUserMachines from '../../../components/admin/userMachines/FormUserMachines';
import useSimpleDataHook from '../../../api/simpleData';
import {Spinner, Pagination, Message, Confirm, Search} from '../../../components';

const UserMachines = () => {
    const [page, setPage] = useState(1);
    const [id, setId] = useState(null);
    const [edit, setEdit] = useState(false);
    const [q, setQ] = useState('');
    const [triggerReset, setTriggerReset] = useState(false);
    const [selectedMachines, setSelectedMachines] = useState([]);

    const {getUserMachines, postUserMachine, updateUserMachine, deleteUserMachine} = useUserMachinesHook({
        page,
        q
    })
    const {getSimpleUsers, getSimpleMachines} = useSimpleDataHook({
        limit: 100000, page: 1
    });

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: {errors}
    } = useForm({
        defaultValues: {
            auth: true
        },
    });

    const {
        data,
        isLoading,
        isError,
        error,
        refetch
    } = getUserMachines;
    const {data: dataUsers} = getSimpleUsers;
    const {data: dataMachines} = getSimpleMachines;

    const {
        isLoading: isLoadingUpdate,
        isSuccess: isSuccessUpdate,
        mutateAsync: mutateAsyncUpdate
    } = updateUserMachine;

    const {
        isLoading: isLoadingDelete,
        mutateAsync: mutateAsyncDelete
    } = deleteUserMachine;

    const {
        isLoading: isLoadingPost,
        isSuccess: isSuccessPost,
        mutateAsync: mutateAsyncPost
    } = postUserMachine;

    useEffect(() => {
        if (isSuccessPost || isSuccessUpdate) formCleanHandler();
    }, [isSuccessPost, isSuccessUpdate]);

    useEffect(() => {
        refetch();
    }, [page]);

    useEffect(() => {
        if (!q) refetch();
    }, [q]);

    const searchHandler = (e) => {
        e.preventDefault();
        refetch();
        setPage(1);
    };

    const deleteHandler = (id) => {
        confirmAlert(Confirm(() => mutateAsyncDelete(id)));
    };

    const submitHandler = async (data) => {
        // console.log("Form Data:", data);
        try {
            if (edit) {
                console.log("Updating User Machine");
                await mutateAsyncUpdate({
                    _id: id,
                    user: data.user,
                    machines: data.machines,
                });
            } else {
                console.log("Creating User Machine");
                await mutateAsyncPost(data);
            }
        } catch (error) {
            console.error("Submission Error:", error);
        }
    };

    const formCleanHandler = () => {
        reset();
        setEdit(false);
        setId(null);
        setTriggerReset(!triggerReset);
        setSelectedMachines([]);
    };

    const editHandler = (userMachine) => {
        setId(userMachine._id);
        setEdit(true);

        // Set the user value in the form
        setValue('user', userMachine.user._id);

        // Map the machines to the format expected by the Select component and set the value
        const machines = userMachine.machines.map(machine => ({
            value: machine._id,
            label: machine.machine_name
        }));
        setSelectedMachines(machines);
        setValue('machines', machines);
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>User Machines</title>
            </Helmet>
            <div className='view-list-top-bar'>

                <h3 className='fw-light text-muted text-center mb-3'>
                    UserMachines List <sup className='fs-6'> [{data && data.total}] </sup>
                </h3>

                <div className='d-flex align-items-center justify-content-between mb-2'>
                    <button
                        className='add-new-entry-button'
                        data-bs-toggle='modal'
                        data-bs-target='#userMachineModal'
                        onClick={() => formCleanHandler()}
                    >
                        Add new UserMachine
                    </button>

                    <Pagination data={data} setPage={setPage}/>
                </div>

                <div className='col-auto full-search'>
                    <Search
                        placeholder='Search by User / Machine'
                        setQ={setQ}
                        q={q}
                        searchHandler={searchHandler}
                    />
                </div>
            </div>
            {isLoading ? (
                <Spinner/>
            ) : isError ? (
                <Message variant="danger">{error}</Message>
            ) : (
                <ViewUserMachines
                    data={data}
                    editHandler={editHandler}
                    deleteHandler={deleteHandler}
                    isLoadingDelete={isLoadingDelete}
                    formCleanHandler={formCleanHandler}
                />
            )}
            <div className='pagination-bottom-block'>
                <Pagination data={data} setPage={setPage}/>
            </div>
            <FormUserMachines
                edit={edit}
                formCleanHandler={formCleanHandler}
                isLoading={isLoading}
                isError={isError}
                errors={errors}
                isLoadingUpdate={isLoadingUpdate}
                isLoadingPost={isLoadingPost}
                register={register}
                handleSubmit={handleSubmit}
                submitHandler={submitHandler}
                watch={watch}
                error={error}
                dataUsers={dataUsers}
                dataMachines={dataMachines}
                setValue={setValue}
                triggerReset={triggerReset}
                selectedMachines={selectedMachines}
                setSelectedMachines={setSelectedMachines}
            />
        </HelmetProvider>
    );
};

export default UserMachines;