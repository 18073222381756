import moment from 'moment'
import DeleteItemIcon from "../../../media/delete-item.svg";
import EditItemIcon from "../../../media/edit-item.svg";

export const ViewOrders = ({
                             data,
                             editHandler,
                             deleteHandler,
                             isLoadingDelete,
                             // images_path
                           }) => {

  // console.log(data);
  return (
      <div className='table-responsive bg-light p-3 mt-2'>
        <table className='table table-sm table-border edit-grid-view'>
          <thead className='border-0'>
          <tr>
            <th>Created</th>
            <th>machine</th>
            <th>order_id</th>
            <th>erp_doc</th>
            {/*<th>orderItems</th>*/}
            {/*<th>Pre Items</th>*/}
            {/*<th>Post Items</th>*/}
            {/*<th>Pre Items Errors</th>*/}
            {/*<th>Post Items Errors</th>*/}
            {/*<th>paymentMethod</th>*/}
            <th>Total Quantity</th>
            <th>Total Price</th>
            <th>cartState</th>
            <th>Transaction Status</th>
            <th>Transaction Message</th>
            {/*<th>orderTime</th>*/}
            <th>Actions</th>
          </tr>
          </thead>

          <tbody>
          {data && data.data ? (
              data.data.map((order) => (
                  <tr key={order._id}
                      className={(order.title === '' || order.date === '' || order.address === '') ? 'empty-values' : ''}>
                    <td className="order-td">{moment(order.createdAt).format('lll')}</td>
                    <td className="order-td">{order.machine_id}</td>

                    <td className="order-td order-id">{order.order_id}</td>
                    <td className="order-td">{order.synced_id}</td>
                    {/*<td>{JSON.stringify(order.orderItems)}</td>*/}
                    {/*<td>{JSON.stringify(order.items_errors)}</td>*/}
                    {/*<td>{JSON.stringify(order.pre_items)}</td>*/}
                    {/*<td>{JSON.stringify(order.post_items)}</td>*/}
                    {/*<td>{JSON.stringify(order.pre_items_errors)}</td>*/}
                    {/*<td>{JSON.stringify(order.post_items_errors)}</td>*/}
                    {/*<td>{order.paymentMethod}</td>*/}
                    <td className="order-td">{order.totalQuantity}</td>
                    <td className="order-td">{order.totalPrice}</td>
                    <td className="order-td" data-status={order.cartState}>{order.cartState}</td>
                    <td className="order-td" data-status={order.transactionStatus}>{order.transactionStatus}</td>
                    <td className="order-td">{order.transactionStatusMessage}</td>
                    {/*<td className="order-td">{formatOrderTime(order.orderTime)}</td>*/}

                    <td>
                      <div className='btn-group'>
                          <button
                              className='btn'
                              onClick={() => editHandler(order)}
                              data-bs-toggle='modal'
                              data-bs-target='#orderModal'
                          >
                              <img src={EditItemIcon} alt="Edit"
                                   className="icon-img action-icons-img"/>
                          </button>

                          <button
                              className='btn'
                              onClick={() => deleteHandler(order._id)}
                            disabled={isLoadingDelete}
                        >
                          {isLoadingDelete ? (
                              <span className='spinner-border spinner-border-sm'/>
                          ) : (
                              <span>
<img src={DeleteItemIcon} alt="Delete"
     className="icon-img action-icons-img"/>
                        </span>
                          )}
                          </button>
                      </div>
                    </td>
                  </tr>
              ))) : (
              // Render an error message when data is undefined or has no data
              <tr>
                <td colSpan="8">Error: Failed to fetch order data</td>
              </tr>
          )}
          </tbody>
        </table>
      </div>
  )
}

export default ViewOrders
