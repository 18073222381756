import React, {memo} from 'react';
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa';

const Pagination = memo(({data, setPage}) => {
    return data ? (
       <div className='text-end pagination-block my-1'>
      <span className='btn '>
        {data.startIndex} - {data.endIndex} of {data.total}
      </span>
            <span
                onClick={() => setPage(data.page - 1)}
                className={`btn mx-1 ${data.page === 1 && 'disabled'}`}
            >
        <FaChevronLeft className='mb-1'/>
      </span>
            <span
                onClick={() => setPage(data.page + 1)}
                className={`btn ${data.page === data.pages && 'disabled'}`}
            >
        <FaChevronRight className='mb-1'/>
      </span>
        </div>
    ) : null;
});

// Display name for the memoized component
Pagination.displayName = 'Pagination';

export default Pagination;
