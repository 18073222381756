import { Helmet, HelmetProvider } from 'react-helmet-async'
import {Link} from "react-router-dom";
import React from "react";

const NotFound = () => {
  return (
    <>
        <HelmetProvider>
          <Helmet>
            <title>404 - Not Found</title>
            <meta property='og:title' content='404 - Not Found' key='title' />
          </Helmet>
        </HelmetProvider>
        <div
        className='not-found-page-container'
        style={{ height: '70vh' }}
      >
        <p>
            <span className='text-muted'>
              <span className='fw-bold'>404 </span> Not Found
            </span>
        </p>
        <p>
            <Link to={"/kiosk"} className="shop_go_to_shop_link">
                GO TO SHOP
            </Link>
        </p>
      </div>
    </>
  )
}

export default NotFound
