import moment from 'moment'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import DeleteItemIcon from "../../../media/delete-item.svg";
import EditItemIcon from "../../../media/edit-item.svg";

const ViewUsers = ({
  data,
  editHandler,
  deleteHandler,
  isLoadingDelete
}) => {
  return (
    <div className='table-responsive bg-light p-3 mt-2'>
      <table className='table table-sm table-border'>
        <thead className='border-0'>
          <tr>
            <th>Joined Date</th>
            <th>Name</th>
            <th>Email</th>
            <th>Confirmed</th>
            <th>Blocked</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data.data.map((user) => (
              <tr key={user._id}>
                <td>{moment(user.createdAt).format('lll')}</td>
                <td>{user.name}</td>
                <td>
                  <a href={`mailto:${user.email}`}>{user.email}</a>
                </td>
                <td>
                  {user.confirmed ? (
                    <FaCheckCircle className='text-success' />
                  ) : (
                    <FaTimesCircle className='text-danger' />
                  )}
                </td>
                <td>
                  {user.blocked ? (
                    <FaCheckCircle className='text-success' />
                  ) : (
                    <FaTimesCircle className='text-danger' />
                  )}
                </td>

                <td>
                  <div className='btn-group'>
                    <button
                        className='btn'
                        onClick={() => editHandler(user)}
                        data-bs-toggle='modal'
                        data-bs-target='#userModal'
                    >
                      <img src={EditItemIcon} alt="Edit"
                           className="icon-img action-icons-img"/>
                    </button>

                    <button
                        className='btn'
                        onClick={() => deleteHandler(user._id)}
                      disabled={isLoadingDelete}
                    >
                      {isLoadingDelete ? (
                        <span className='spinner-border spinner-border-sm' />
                      ) : (
                          <span>
        <img src={DeleteItemIcon} alt="Delete"
             className="icon-img action-icons-img"/>
                        </span>
                      )}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default ViewUsers
