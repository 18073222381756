import dynamicAPI from './dynamicAPI';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useMessage } from '../context/AdminMessageContext';

const url = '/api/auth/client-permissions';
const queryKey = 'client-permissions';

export default function useClientPermissionsHook(props) {
    const { page = 1, id, q = '', limit = 25 } = props;
    const queryClient = useQueryClient();
    const { setSuccessMessage, setErrorMessage } = useMessage(); // Use the message context

    const getClientPermissions = useQuery(
        [queryKey, page, limit],
        async () => await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}`, {}),
        { retry: 0 }
    );

    const getClientPermissionById = useQuery(
        [queryKey, id],
        async () => await dynamicAPI('get', `${url}/${id}`, {}),
        { retry: 0, enabled: !!id }
    );

    const updateClientPermission = useMutation(
        async (obj) => await dynamicAPI('put', `${url}/${obj._id}`, obj),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('Client Permission updated successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to update Client Permission.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while updating Client Permission.';
                setErrorMessage(errorMessage);
            }
        }
    );

    const deleteClientPermission = useMutation(
        async (id) => await dynamicAPI('delete', `${url}/${id}`, {}),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('Client Permission deleted successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to delete Client Permission.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while deleting Client Permission.';
                setErrorMessage(errorMessage);
            }
        }
    );

    const postClientPermission = useMutation(
        async (obj) => await dynamicAPI('post', url, obj),
        {
            retry: 0,
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('Client Permission created successfully.');
                    queryClient.invalidateQueries([queryKey]);
                } else {
                    const errorMessage = data.error || 'Failed to create Client Permission.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while creating Client Permission.';
                setErrorMessage(errorMessage);
            }
        }
    );

    return {
        getClientPermissions,
        updateClientPermission,
        deleteClientPermission,
        postClientPermission,
        getClientPermissionById,
    };
}
