import { createContext, useState, useEffect } from 'react';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const storedUserInfo = localStorage.getItem('userInfo');
    return storedUserInfo ? { userInfo: JSON.parse(storedUserInfo) } : null;
  });

  useEffect(() => {
    if (auth?.userInfo) {
      localStorage.setItem('userInfo', JSON.stringify(auth.userInfo));
    } else {
      localStorage.removeItem('userInfo');
    }
  }, [auth]);

  return (
      <AuthContext.Provider value={{ auth, setAuth }}>
        {children}
      </AuthContext.Provider>
  );
};

export default AuthContext;
