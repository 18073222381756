import React from 'react'
// import {Link} from "react-router-dom";

const imagesPath = process.env.REACT_APP_BACKEND_URL+"/api/media/products/";


function Product({product, onProductClick, i18n}) {

  return (
    <div className="item-container" onClick={onProductClick}>

        <div className="item-container-content">
        {/*<Link className="link-block" to={`/kiosk/machine/${product._id}`}>*/}
            {product.images.length > 0 ? (
                <img className="item-product-img" src={`${imagesPath}${product.images[0].path}`} alt="Product Thumbnail" />
            ) : (
                <img className="item-product-img" src={`${imagesPath}imall-item-no-image.png`} alt="Default Thumbnail" />
            )}
            <div className="item-info-block">
                <div className="title">
                    <span>{product.name?.[i18n.language] ?? product.name?.el}</span>
                </div>
                <div className="price">
                    <span>  {isNaN(parseFloat(product.price)) ? "Invalid Price" : parseFloat(product.price).toFixed(2)}€</span>
                </div>
            </div>
        {/*</Link>*/}
        {/*<button className="add-tocart-button" onClick={() => addCartItems(product)}>Add to card</button>*/}
        </div>
    </div>
  )
}


export default Product
