import moment from 'moment'
import DeleteItemIcon from "../../../media/delete-item.svg";
import EditItemIcon from "../../../media/edit-item.svg";

export const ViewCategories = ({
                                   data,
                                   editHandler,
                                   deleteHandler,
                                   isLoadingDelete,
                                   useTranslation
                               }) => {
    // console.log(data);
    const {i18n, t} = useTranslation(); // Use i18n instance to access current language

    return (
        <div className='table-responsive bg-light p-3 mt-2'>
            <table className='table table-sm table-border edit-grid-view'>
                <thead className='border-0'>
                <tr>
                    <th></th>
                    <th>{t("Created")}</th>
                    <th>category_id</th>
                    {/*<th>categoryItems</th>*/}
                    <th>{t("Category Name")}</th>
                    <th>{t("Actions")}</th>
                </tr>
                </thead>

                <tbody>
                {data && data.data ? (
                    data.data.map((category) => (
                        <tr key={category._id}
                            className={(category.title === '' || category.date === '' || category.address === '') ? 'empty-values' : ''}>
                            {category.images.length > 0 ? (
                                <td className="category-list-thumbnail"><img
                                    src={`${data.images_path}${category.images[0]['path']}`} alt="Thumbnail"/></td>
                            ) : (
                                <td className="category-list-thumbnail"><img
                                    src={`${data.images_path}imall-item-no-image.png`} alt="Default Thumbnail"/></td>
                            )}
                            <td>{moment(category.createdAt).format('lll')}</td>
                            <td>{category.category_id}</td>
                            <td>{category.category_name ? (category.category_name[i18n.language] || category.category_name['el']) : 'Default Category Name'}</td>
                            <td>
                                <div className='btn-group'>
                                    <button
                                        className='btn'
                                        onClick={() => editHandler(category)}
                                        data-bs-toggle='modal'
                                        data-bs-target='#categoryModal'
                                    >
                                        <img src={EditItemIcon} alt="Edit"
                                             className="icon-img action-icons-img"/>
                                    </button>

                                    <button
                                        className='btn'
                                        onClick={() => deleteHandler(category._id)}
                                        disabled={isLoadingDelete}
                                    >
                                        {isLoadingDelete ? (
                                            <span className='spinner-border spinner-border-sm'/>
                                        ) : (
                                            <span>
        <img src={DeleteItemIcon} alt="Delete"
             className="icon-img action-icons-img"/>
                        </span>
                                        )}
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))) : (
                    // Render an error message when data is undefined or has no data
                    <tr>
                        <td colSpan="8">Error: Failed to fetch category data</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    )
}

export default ViewCategories
