import dynamicAPI from './dynamicAPI';
import { useMutation } from 'react-query';
import { useMessage } from '../context/AdminMessageContext'; // Import message context

const url = '/api/upload/profile-image';
// const queryKey = 'profile-image';

export default function useUploadHook() {
    const { setSuccessMessage, setErrorMessage } = useMessage(); // Destructure message functions

    const postUploadProfileImage = useMutation(
        async (obj) => {
            // Append the type to the formData if not already present
            if (!obj.formData.has('type')) {
                obj.formData.append('type', obj.type);
            }

            // Make POST request with formData, now containing the file and the type
            return await dynamicAPI('post', `${url}`, obj.formData);
        },
        {
            retry: 0, // Disable retry
            refetchOnWindowFocus: false,  // Disable refetch on window focus
            onSuccess: (data) => {
                if (data.isSuccess) {
                    setSuccessMessage('File uploaded successfully! Please update your profile to apply the new image.');
                } else {
                    const errorMessage = data.error || 'File upload failed.';
                    setErrorMessage(errorMessage);
                }
            },
            onError: (error) => {
                const errorMessage = error.message || 'An error occurred during file upload.';
                setErrorMessage(errorMessage);
            },
        }
    );

    return { postUploadProfileImage };
}
