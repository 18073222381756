import React from 'react';
import { FaCheckCircle, FaTimesCircle, FaTimes } from 'react-icons/fa';

const AdminMessage = ({ variant, children, onClose }) => (
    <div
        className='message-admin-master position-fixed d-flex justify-content-center align-items-center animate__animated animate__fadeIn'
        style={{ zIndex: 900000, top: 0, left: 0, right: 0, bottom: 0, width: '100vw', height: '100vh' }}
    >
        <div
            className={`toast show text-${variant} position-relative`}
            role='alert'
            style={{ width: 'fit-content' }}
        >
            <button
                type="button"
                className="btn-close-popup-admin position-absolute top-0 end-0 m-1"
                aria-label="Close"
                onClick={onClose}
            >
                <FaTimes />
            </button>
            <div className='toast-body admin-toast text-center'>
                {variant === 'success' ? (
                    <FaCheckCircle className='fs-4 mr-3 mb-1' />
                ) : (
                    <FaTimesCircle className='fs-4 mr-3 mb-1' />
                )}{' '}
                {children}
            </div>
        </div>
    </div>
);

export default AdminMessage;
