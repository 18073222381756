import React, {useState} from 'react';

const EditModal = ({isOpen, onClose, children}) => {
    if (!isOpen) {
        return null;
    }

    const handleOverlayClick = (event) => {
        // Check if the click is on the overlay itself, not its children
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    return (
        <div className="entry-edit-modal-overlay" onClick={handleOverlayClick}>
            <div className="entry-edit-modal-content" onClick={e => e.stopPropagation()}>
                <button onClick={onClose} className="entry-edit-modal-close">Close</button>
                {children}
            </div>
        </div>
    );
};

// Assuming you're passing the i18n object or the current language as a prop
const EntryEditFormMachines = ({isOpen, onRequestClose, products, onSelect, i18n}) => {
    const [searchTerm, setSearchTerm] = useState('');
    console.log(searchTerm);

    const filteredProducts = products.filter(product => {
        // Accessing the product name based on the current language or defaulting to 'el'
        const productName = product.name?.[i18n.language] ?? product.name?.el;

        // Normalize the searchTerm to lower case
        const normalizedSearchTerm = searchTerm.toLowerCase();

        // Check if productName matches the search term
        const nameMatches = typeof productName === 'string' && productName.toLowerCase().includes(normalizedSearchTerm);

        // Check if the product's UPC matches the search term (assuming product.upc is a string)
        const barcodeMatches = typeof product.barcode === 'string' && product.barcode.includes(normalizedSearchTerm);

        // Return true if either the name or barcode matches the search term
        return nameMatches || barcodeMatches;
    });

    return (
        <EditModal
            isOpen={isOpen}
            onClose={onRequestClose}
        >

            <input className='search-item-input'
                   type="text"
                   placeholder="Search product..."
                   value={searchTerm}
                   onChange={(e) => setSearchTerm(e.target.value)}
            />

            {filteredProducts.length > 0 ? (
                <ul>
                    {filteredProducts.map((product) => (
                        <li
                            className='search-item-data'
                            key={product.barcode}
                            onClick={() => onSelect(product)}
                        >
                            {product.name?.[i18n.language] ?? product.name?.el} - ({product.barcode})
                        </li>
                    ))}
                </ul>
            ) : (
                <div>No item found with this name.</div>
            )}
        </EditModal>
    );
};

export default EntryEditFormMachines;