import dynamicAPI from './dynamicAPI';
import { useMutation, useQueryClient } from 'react-query';
import useAuth from '../hooks/useAuth';
import { useMessage } from '../context/AdminMessageContext';

const url = '/api/auth';

const useAuthHook = () => {
    const { setAuth } = useAuth();
    const queryClient = useQueryClient();
    const { setSuccessMessage, setErrorMessage } = useMessage();


    const postLogin = useMutation(
        async (obj) => await dynamicAPI('post', `${url}/login`, obj),
        {
            retry: 0,
            onSuccess: () => {
                queryClient.invalidateQueries(['login'])
                setSuccessMessage('Login successful.')
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred during login.'
                setErrorMessage(errorMessage)
            },
        }
    )

    const postLogout = () => {
        localStorage.removeItem('userRole')
        localStorage.removeItem('userInfo')
        setAuth(null)
        setSuccessMessage('Logged out successfully.')
        return true
    }

    const postForgotPassword = useMutation(
        async (obj) => await dynamicAPI('post', `${url}/forgot-password`, obj),
        {
            retry: 0,
            onSuccess: () => {
                queryClient.invalidateQueries(['forgot password']);
                setSuccessMessage('Password reset instructions sent to your email.');
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while sending password reset instructions.';
                setErrorMessage(errorMessage);
            },
        }
    )

    const postResetPassword = useMutation(
        async (obj) => await dynamicAPI('post', `${url}/reset-password`, obj),
        {
            retry: 0,
            onSuccess: () => {
                queryClient.invalidateQueries(['reset password']);
                setSuccessMessage('Password reset successfully.');
            },
            onError: (error) => {
                const errorMessage = error?.error || 'An error occurred while resetting the password.';
                setErrorMessage(errorMessage);
            },
        }
    )

  return { postLogin, postLogout, postForgotPassword, postResetPassword }
}

export default useAuthHook
