import dynamicAPI from './dynamicAPI';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useMessage } from '../context/AdminMessageContext';

const url = '/api/auth/products';
const queryKey = 'products';

export default function useProductsHook(props) {
    const { page = 1, id, q = '', limit = 25 } = props;
    const queryClient = useQueryClient();
    const { setSuccessMessage, setErrorMessage } = useMessage();

    const getProducts = useQuery(
        [queryKey, page, limit],
        async () => {
            const response = await dynamicAPI('get', `${url}?page=${page}&q=${q}&limit=${limit}`);
            if (response.isError) throw new Error(response.error);
            return response;
        },
        { retry: 0 }
    );

    const getProductById = useQuery(
        [queryKey, id],
        async () => {
            const response = await dynamicAPI('get', `${url}/${id}`);
            if (response.isError) throw new Error(response.error);
            return response;
        },
        { retry: 0, enabled: !!id }
    );

    const updateProduct = useMutation(
        async (obj) => {
            const response = await dynamicAPI('put', `${url}/${obj._id}`, obj);
            if (response.isError) throw new Error(response.error);
            return response;
        },
        {
            retry: 0,
            onSuccess: () => {
                setSuccessMessage('Product updated successfully.');
                queryClient.invalidateQueries([queryKey]);
            },
            onError: (error) => {
                setErrorMessage(error.message || 'Failed to update product.');
            }
        }
    );

    const deleteProduct = useMutation(
        async (id) => {
            const response = await dynamicAPI('delete', `${url}/${id}`);
            if (response.isError) throw new Error(response.error);
            return response;
        },
        {
            retry: 0,
            onSuccess: () => {
                setSuccessMessage('Product deleted successfully.');
                queryClient.invalidateQueries([queryKey]);
            },
            onError: (error) => {
                setErrorMessage(error.message || 'Failed to delete product.');
            }
        }
    );

    const postProduct = useMutation(
        async (obj) => {
            const response = await dynamicAPI('post', url, obj);
            if (response.isError) throw new Error(response.error);
            return response;
        },
        {
            retry: 0,
            onSuccess: () => {
                setSuccessMessage('Product created successfully.');
                queryClient.invalidateQueries([queryKey]);
            },
            onError: (error) => {
                setErrorMessage(error.message || 'Failed to create product.');
            }
        }
    );

    return { getProducts, updateProduct, deleteProduct, postProduct, getProductById };
}
