import { useState } from 'react';
import DeleteItemIcon from "../../../media/delete-item.svg";
import EditItemIcon from "../../../media/edit-item.svg";

const ViewUserRoles = ({
                         data,
                         editHandler,
                         deleteHandler,
                       }) => {
  const [loadingId, setLoadingId] = useState(null);  // State to manage which item is currently being deleted

  const handleDelete = async (id) => {
    setLoadingId(id);
    await deleteHandler(id);
    setLoadingId(null);
  };

  // Sort the data by role.type
  const sortedData = data && data.data.sort((a, b) => {
      if (a.role && b.role) {
            return a.role.type.localeCompare(b.role.type);
      }
      return 0;
  });

  return (
      <div className='table-responsive bg-light p-3 mt-2'>
        <table className='table table-sm table-border'>
          <thead className='border-0'>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Role Type</th>
            <th>Actions</th>
          </tr>
          </thead>

          <tbody>
          {sortedData &&
              sortedData.map((userRole) => (
                  <tr key={userRole._id}>
                    <td>{userRole.user && userRole.user.name}</td>
                    <td>{userRole.user && userRole.user.email}</td>
                    <td>{userRole.role && userRole.role.name}</td>
                    <td>{userRole.role && userRole.role.type}</td>

                    <td>
                      <div className='btn-group'>
                          <button
                              className='btn'
                              onClick={() => editHandler(userRole)}
                              data-bs-toggle='modal'
                              data-bs-target='#userRoleModal'
                          >
                              <img src={EditItemIcon} alt="Edit"
                                   className="icon-img action-icons-img"/>
                          </button>

                          <button
                              className='btn'
                              onClick={() => handleDelete(userRole._id)}
                            disabled={loadingId === userRole._id}  // Disable only the button that is being deleted
                        >
                          {loadingId === userRole._id ? (
                              <span className='spinner-border spinner-border-sm' />
                          ) : (
                              <img src={DeleteItemIcon} alt="Delete"
                                   className="icon-img action-icons-img"/>
                          )}
                          </button>
                      </div>
                    </td>
                  </tr>
              ))}
          </tbody>
        </table>
      </div>
  );
};

export default ViewUserRoles;
