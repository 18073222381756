import moment from 'moment';
import DeleteItemIcon from "../../../media/delete-item.svg";
import EditItemIcon from "../../../media/edit-item.svg";

export const ViewBrands = ({
                                   data,
                                   editHandler,
                                   deleteHandler,
                                   isLoadingDelete,
                                    useTranslation
                               }) => {
    // console.log(data);
    const {t} = useTranslation(); // Use i18n instance to access current language

    return (
        <div className='table-responsive bg-light p-3 mt-2'>
            <table className='table table-sm table-border edit-grid-view'>
                <thead className='border-0'>
                <tr>
                    <th></th>
                    <th>{t("Created")}</th>
                    <th>brand_id</th>
                    {/*<th>brandItems</th>*/}
                    <th>{t("Brand Name")}</th>
                    <th>{t("Actions")}</th>
                </tr>
                </thead>

                <tbody>
                {data && data.data ? (
                    data.data.map((brand) => (
                        <tr key={brand._id}
                            className={(brand.title === '' || brand.date === '' || brand.address === '') ? 'empty-values' : ''}>
                            {brand.images.length > 0 ? (
                                <td className="brand-list-thumbnail"><img
                                    src={`${data.images_path}${brand.images[0]['path']}`} alt="Thumbnail"/></td>
                            ) : (
                                <td className="brand-list-thumbnail"><img
                                    src={`${data.images_path}imall-item-no-image.png`} alt="Default Thumbnail"/></td>
                            )}
                            <td>{moment(brand.createdAt).format('lll')}</td>
                            <td>{brand.brand_id}</td>
                            <td>{brand.brand_name}</td>
                            <td>
                                <div className='btn-group'>
                                    <button
                                        className='btn'
                                        onClick={() => editHandler(brand)}
                                        data-bs-toggle='modal'
                                        data-bs-target='#brandModal'
                                    >
                                        <img src={EditItemIcon} alt="Edit"
                                             className="icon-img action-icons-img"/>
                                    </button>

                                    <button
                                        className='btn'
                                        onClick={() => deleteHandler(brand._id)}
                                        disabled={isLoadingDelete}
                                    >
                                        {isLoadingDelete ? (
                                            <span className='spinner-border spinner-border-sm'/>
                                        ) : (
                                            <span>
                                        <img src={DeleteItemIcon} alt="Delete"
                                             className="icon-img action-icons-img"/>
                                        </span>
                                        )}
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))) : (
                    // Render an error message when data is undefined or has no data
                    <tr>
                        <td colSpan="8">Error: Failed to fetch brand data</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    )
}

export default ViewBrands
