import React, {useContext, useEffect, useState} from 'react';
import Products from '../../components/kiosk/items/Products';
import Categories from '../../components/kiosk/categories/Categories';
import Cart from '../../components/kiosk/cart/Cart';
import Header from '../../components/kiosk/Header';
import Navbar from '../../components/kiosk/Navbar';
// import {LanguageSwitcherIcons} from "../../components/shared/LanguageSwitcher";

import { ShoppingContext } from '../../context/ShoppingContext';
import '../../components/kiosk/css/App.css';
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
// import "../../css/ui-view.css";

function KioskLandingPage() {
    const { i18n, t } = useTranslation();
    const { machine_id, categories_id } = useParams();
    const { dispatch, startCountdown } = useContext(ShoppingContext);
    const [ startShopping, setStartShopping] = useState(false);
    const [ selectedCategory, setSelectedCategory] = useState(null);


    useEffect(() => {
        if (machine_id) {
            console.log('machine_id_use_effect ' + machine_id);
            console.log('categories_id_use_effect ' + categories_id);
            dispatch({ type: 'SET_MACHINE_ID', payload: machine_id });
        }
    }, [machine_id, dispatch]);

    // const handleStartShopping = () => {
    //     setStartShopping(true);
    //     startCountdown();
    // };

    const handleStartShoppingWithLanguage = (language) => {
        i18n.changeLanguage(language); // Change the language
        setStartShopping(true); // Start shopping
        startCountdown(); // Start the countdown
    };

    const goBackToCategories = () => {
        if (selectedCategory) {
            // If a category is selected, just reset the selected category
            setSelectedCategory(null);
        } else {
            // If no category is selected, it means we are in the categories view
            // So set startShopping to false to go back to the start shopping container
            setStartShopping(false);
        }
    };
    const handleCategorySelect = (category) => {
        // console.log(category);
        setSelectedCategory(category);
        // Optionally, navigate to a different page or change the view
    };
    return (
        <>
            <title>Shopping Machine UI</title>
            <meta property="og:title" content="iMall Shopping Kiosk" key="title"/>

            <div className="ui-block" style={{ width: '100%'}}>
                {!startShopping ? (
                    <div className="start-shopping-container">
                        <img
                            src='/img/shop_background_1080.png'
                            className='shop-background'
                            alt='logo'
                        />
                        <div className="start-shopping-container-block">
                            <div className="start-shopping-container-block">
                                <div className="start-shopping-button"
                                     onClick={() => handleStartShoppingWithLanguage(i18n.language)}>
                                    {t("Start shopping")}
                                </div>
                                <div className="start-shopping-button-icon english-language"
                                     onClick={() => handleStartShoppingWithLanguage('en')}>
                                    <img
                                        src='/img/english_background.png'
                                        alt='english_background'
                                    />
                                </div>
                                <div className="start-shopping-button-icon greek-language"
                                     onClick={() => handleStartShoppingWithLanguage('el')}>
                                    <img
                                        src='/img/greek_background.png'
                                        alt='greek_background'
                                    />
                                </div>
                            </div>
                            {/*<LanguageSwitcherIcons />*/}
                        </div>
                    </div>
                ) : (
                    <>
                        <Header machine_id={machine_id}/>
                        <div className="container">
                            <Navbar goBack={goBackToCategories} showGoBackButton={selectedCategory !== null}/>
                            {!selectedCategory ? (
                                <Categories
                                    onCategorySelect={handleCategorySelect}
                                    i18n={i18n}
                                />
                            ) : (
                                <Products
                                    category={selectedCategory}
                                    i18n={i18n}
                                />
                            )}
                            <Cart/>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default KioskLandingPage;