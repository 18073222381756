import moment from 'moment';
import DeleteItemIcon from "../../../media/delete-item.svg";
import EditItemIcon from "../../../media/edit-item.svg";

export const ViewUserMachines = ({
                                     data,
                                     editHandler,
                                     deleteHandler,
                                     isLoadingDelete
                                 }) => {
    return (
        <div className='table-responsive bg-light p-3 mt-2'>

            <table className='table table-sm table-border edit-grid-view'>
                <thead className='border-0'>
                <tr>
                    <th>Created</th>
                    <th>User</th>
                    <th>Machines</th>
                    <th>Actions</th>
                </tr>
                </thead>

                <tbody>
                {data && data.data ? (
                    data.data.map((userMachine) => (
                        <tr key={userMachine._id}>
                            <td className="order-td">{moment(userMachine.createdAt).format('lll')}</td>
                            <td className="order-td">{userMachine.user.name}</td>
                            <td className="order-td">
                                {userMachine.machines && userMachine.machines.length > 0 ? (
                                    userMachine.machines.map((machine) => (
                                        <div key={machine._id}>
                                            {machine.machine_name + " : " + machine.kiosk_external_id}
                                            {/*<img*/}
                                            {/*    src={userMachinesIcon}*/}
                                            {/*    alt="Machine Icon"*/}
                                            {/*    style={{*/}
                                            {/*        width: '20px',*/}
                                            {/*        marginLeft: '8px'*/}
                                            {/*    }}*/}
                                            {/*/>*/}
                                        </div>
                                    ))
                                ) : (
                                    <div>No Machines Assigned</div>
                                )}
                            </td>
                            <td>
                                <div className='btn-group'>
                                    <button
                                        className='btn'
                                        onClick={() => editHandler(userMachine)}
                                        data-bs-toggle='modal'
                                        data-bs-target='#userMachineModal'
                                    >
                                        <img src={EditItemIcon} alt="Edit"
                                             className="icon-img action-icons-img"/>
                                    </button>

                                    <button
                                        className='btn'
                                        onClick={() => deleteHandler(userMachine._id)}
                                        disabled={isLoadingDelete}
                                    >
                                        {isLoadingDelete ? (
                                            <span className='spinner-border spinner-border-sm' />
                                        ) : (
                                            <img src={DeleteItemIcon} alt="Delete"
                                                 className="icon-img action-icons-img"/>
                                        )}
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="8">Error: Failed to fetch User Machines data</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
}

export default ViewUserMachines;
