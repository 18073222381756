import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import DeleteItemIcon from "../../../media/delete-item.svg";
import EditItemIcon from "../../../media/edit-item.svg";

const renderMethodBadge = (color, method, permission) => {
  return (
      permission.method === method && (
          <span className={`badge bg-${color} px-2`}>{permission.method}</span>
      )
  );
};

const ViewPermissions = ({
                           data,
                           editHandler,
                           deleteHandler,
                         }) => {
  return (
      <div className='table-responsive bg-light p-3 mt-2'>
        <table className='table table-sm table-border'>
          <thead className='border-0'>
          <tr>
              <th>Auth</th>
              <th>Name</th>
              <th>Description</th>
              <th>Method</th>
              <th>Route</th>
              <th>Actions</th>
          </tr>
          </thead>

            <tbody>
          {data &&
              data.data.map((permission) => (
                  <tr key={permission._id}>
                      <td>
                          {permission.auth ? (
                              <FaCheckCircle className='text-success' />
                          ) : (
                              <FaTimesCircle className='text-danger' />
                          )}
                      </td>
                      <td>{permission.name}</td>
                      <td>{permission.description}</td>
                      <td>
                        {renderMethodBadge('primary', 'GET', permission)}
                        {renderMethodBadge('success', 'POST', permission)}
                        {renderMethodBadge('warning', 'PUT', permission)}
                        {renderMethodBadge('danger', 'DELETE', permission)}
                    </td>
                      <td>{permission.route}</td>

                    <td>
                      <div className='btn-group'>
                          <button
                              className='btn'
                              onClick={() => editHandler(permission)}
                              data-bs-toggle='modal'
                              data-bs-target='#permissionModal'
                          >
                              <img src={EditItemIcon} alt="Edit"
                                   className="icon-img action-icons-img"/>
                          </button>

                          <button
                              className='btn'
                              onClick={() => deleteHandler(permission._id)}
                          >
                              <img src={DeleteItemIcon} alt="Delete"
                                   className="icon-img action-icons-img"/>
                          </button>
                      </div>
                    </td>
                  </tr>
              ))}
          </tbody>
        </table>
      </div>
  );
};

export default ViewPermissions;
